import React, { useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom"
const { v4: uuidv4 } = require("uuid")

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  UncontrolledTooltip,
  Col,
  Container,
  Form,
  Input,
  Label,
  Badge,
  Row,
  InputGroup,
  InputGroupText,
  Modal,
  ModalBody,
  ModalHeader,
  FormFeedback,
  ModalFooter,
} from "reactstrap"
import Select from "react-select"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import apiURl from "url"

//firabse
// import { storage } from "config/firebase"

import toastr from "toastr"
import "toastr/build/toastr.min.css"
import TableContainer from "components/Common/TableContainer"
import DeleteModal from "components/Common/DeleteModal"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"

import { useFormik } from "formik"
import * as Yup from "yup"
import { indexOf } from "lodash"
import { Provider } from "react-redux"
import useFetchWarehouses from "hooks/useFetchWarehouse"
import { useSSR } from "react-i18next"
import WareHouseModal from "components/WarehouseModal/houseModal"
import Loader from "hooks/loader"

const AllWarehouses = () => {
  //meta title
  document.title = "Warehouses"

  const { FullHouseData, loading, fetchHouse } = useFetchWarehouses()
  const [deleteModal, setDeleteModal] = useState(false)

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "warehouse_name",
        disableFilters: true,
        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: "City",
        accessor: "warehouse_city",
        disableFilters: true,
        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: "Phone",
        accessor: "warehouse_phone",
        disableFilters: true,
        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: "Code",
        accessor: "warehouse_code",
        disableFilters: true,
        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: "Status",
        accessor: "warehouse_status",
        disableFilters: true,
        Cell: cellProps => {
          switch (cellProps.value) {
            case "active":
              return <Badge className="bg-info font-size-12">Active</Badge>
            case "inActive":
              return <Badge className="bg-danger font-size-12">In-Active</Badge>
          }
        },
      },

      {
        Header: "Action",
        accessor: "id",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <>
              <div className="d-flex gap-3">
                <Link
                  to="#"
                  onClick={() => ViewEditModal(cellProps.value)}
                  className="text-primary"
                >
                  <i className="mdi mdi-eye font-size-18" id="viewtooltip" />
                  <UncontrolledTooltip placement="top" target="viewtooltip">
                    View
                  </UncontrolledTooltip>
                </Link>
                {/* <Link
                  to="#"
                  className="text-danger"
                  onClick={() => showDeleteModal(cellProps.value)}
                >
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                  />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link> */}
              </div>
            </>
          )
        },
      },
    ],
    []
  )

  //Add New
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [editId, setEditId] = useState("")
  const [modalData, setModalData] = useState(false)

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
    setModalData(false)
  }

  //Insert New House
  const addWarehouse = () => {
    setIsModalOpen(true)
    setEditMode(false)
    setModalData(true)
    setEditId("")
  }
  //Edit House
  const ViewEditModal = id => {
    setIsModalOpen(true)
    setEditId(id)
    setEditMode(true)
    setModalData(false)
  }

  //Delete User
  const [delid, setDelid] = useState([])
  const showDeleteModal = id => {
    setDelid(id)
    setDeleteModal(true)
  }
  const handleUsersDelete = async () => {
    try {
      const response = await axios.delete(`${apiURl}/api/deleteUsers/${delid}`)

      if (response.data.success === "success") {
        toastr.success(response.data.message)
        setDeleteModal(false)
        fetchHouse() // Refresh the users list
      } else {
        toastr.error("Failed to delete warehouse.")
      }
    } catch (error) {
      console.error("Error:", error)
    }
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleUsersDelete}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcr umb */}
          <Breadcrumbs title="Ecommerce" breadcrumbItem="Warehouses" />

          <Row className="pt-3">
            <Col lg="12">
              {loading ? (
                <Loader />
              ) : (
                <Card>
                  <CardBody>
                    <Row>
                      <Col>
                        <div className="float-end">
                          <Button
                            type="button"
                            color="primary"
                            className="w-md btn btn-sm"
                            onClick={() => addWarehouse()}
                          >
                            <i className="fa fa-fw fa-plus" />
                            Create new
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    <TableContainer
                      columns={columns}
                      data={FullHouseData}
                      isGlobalFilter={true}
                      isAddOptions={false}
                      customPageSize={10}
                    />
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
        </Container>
      </div>
      <WareHouseModal
        isOpen={isModalOpen}
        toggle={toggleModal}
        modalData={modalData}
        editMode={editMode}
        {...(editId && { editId })}
        fetchHouse={fetchHouse}
      />
    </React.Fragment>
  )
}

export default AllWarehouses
