import { useState, useEffect } from "react"
import axios from "axios"
import apiURl from "url"
import userEvent from "@testing-library/user-event"

const useFetchExpense = () => {
  const [expense, setexpense] = useState([])
  const [loading, setLoading] = useState(true)

  const fetchexpense = async () => {
    try {
      setLoading(true)
      const response = await axios.get(`${apiURl}/api/getexpense`)
      setexpense(response.data)
    } catch (error) {
      console.error("Error fetching expense:", error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchexpense()
  }, [])

  return { expense, loading, fetchexpense }
}

export default useFetchExpense
