import React, { useEffect, useState } from "react"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  Form,
  Input,
  InputGroup,
  Table,
  Label,
  Card,
  CardBody,
  Badge,
} from "reactstrap"
import apiURl from "url"
import axios from "axios"

import toastr from "toastr"
import "toastr/build/toastr.min.css"
import SalePaymentInvoice from "./InvoiceModal"

const MakePaymentModal = ({
  isOpen,
  toggle,
  totalPrice,
  totalQuantity,
  RefNo,
  selectedProduct,
  ResetHoldValue,
  ResetDirectSaleValue,
}) => {
  const initialSaleData = {
    total_amount: "",
    paid_amount: "",
    change_back: "",
    payment_mode: "cash",
    payment_status: "paid",
    sale_note: "",
  }
  const [SaleData, setSaleData] = useState(initialSaleData)

  const handleSaleData = e => {
    const { name, value } = e.target

    setSaleData(prevData => ({
      ...prevData,
      [name]: value,
    }))
  }

  const [loading, setLoading] = useState(false)

  const handleSubmit = async e => {
    e.preventDefault()
    const value = e.target.value
    setLoading(true)
    // Ensure selectedProduct is always an array

    const HData = new FormData()

    selectedProduct.forEach((product, index) => {
      for (const key in product) {
        if (product.hasOwnProperty(key)) {
          // Append with index to differentiate between products
          HData.append(`products[${index}][${key}]`, product[key])
        }
      }
    })
    try {
      const url = `${apiURl}/api/Add_POS_Sale_data`
      const response = await axios.post(url, HData, {
        headers: {
          "Content-Type": "application/json",
        },
      })

      if (response.data.success === "success") {
        toggle()
        RefNo !== "" ? ResetHoldValue(true) : ResetHoldValue(false)
        toastr.success(response.data.message)
        ResetDirectSaleValue(true)
      } else {
        toastr.error("Failed")
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toastr.error(error.response.data.message)
      } else {
        toastr.error("An unexpected error occurred.")
      }
      console.error("Error:", error)
    } finally {
      setLoading(false)
      if (value === "submit_print") {
        setInvoiceModal(true)
      }
    }
  }

  //Return Amount
  const [paidAmount, setPaidAmount] = useState(totalPrice)
  const [returnAmount, setReturnAmount] = useState(0)

  // Update `paidAmount` whenever `totalPrice` changes
  useEffect(() => {
    setPaidAmount(totalPrice)
  }, [totalPrice])

  // Calculate `returnAmount` whenever `paidAmount` or `totalPrice` changes
  useEffect(() => {
    setReturnAmount(paidAmount - totalPrice)
  }, [paidAmount, totalPrice])

  const handlePaidAmount = e => {
    setPaidAmount(Number(e.target.value)) // Ensure value is treated as a number
  }

  //Print
  const [invoiceModal, setInvoiceModal] = useState(false)

  const InvoiceToggle = () => {
    setInvoiceModal(!invoiceModal)
  }

  //Handel Submit And Print
  const handleSubmitPrint = () => {
    toggle()
    setInvoiceModal(true)
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        role="dialog"
        size="xl"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={toggle}
      >
        <div>
          <Form>
            <ModalHeader toggle={toggle}>Make Payment</ModalHeader>
            <ModalBody>
              <Row>
                <Col xl={8}>
                  <Row>
                    <Col className="col-6">
                      <div className="mb-3">
                        <label>Paying Amount</label>
                        <Input
                          className="form-control"
                          type="number"
                          min={0}
                          value={parseFloat(paidAmount).toFixed(0)}
                          name="paid_amount"
                          onChange={handlePaidAmount}
                          onWheel={e => e.target.blur()}
                        />
                      </div>
                      <div className="mb-3">
                        <label>Return Amount</label>
                        <Input
                          className="form-control"
                          name="change_back"
                          type="number"
                          value={parseFloat(returnAmount).toFixed(2)}
                          disabled
                        />
                      </div>

                      <div className="mb-3">
                        <Label
                          className="control-label"
                          htmlFor="payment_status"
                        >
                          Payment Status:
                        </Label>
                        <select
                          defaultValue="0"
                          className="form-select"
                          onChange={handleSaleData}
                          name="payment_status"
                          value={SaleData.warehouse_status}
                        >
                          <option value="paid" selected>
                            Paid
                          </option>
                          <option value="un_paid">Unpaid</option>
                        </select>
                      </div>
                    </Col>
                    <Col className="col-6">
                      <div className="mb-3">
                        <label>Total Amount</label>
                        <Input
                          className="form-control"
                          type="number"
                          name="total_amount"
                          value={totalPrice}
                          disabled
                        />
                      </div>
                      <div className="mb-3">
                        <Label className="control-label" htmlFor="payment_type">
                          Payment Type:
                        </Label>
                        <select
                          defaultValue="0"
                          className="form-select"
                          onChange={handleSaleData}
                          name="payment_type"
                          value={SaleData.payment_type}
                        >
                          <option value="cash" selected>
                            Cash
                          </option>
                          <option value="cheque">Cheque</option>
                          <option value="bank_transfer">Bank Transfer</option>
                          <option value="other">Other</option>
                        </select>
                      </div>
                    </Col>
                    <Col className="col-12">
                      <div className="mb-12">
                        <label>Note</label>
                        <Input
                          className="form-control"
                          type="textarea"
                          rows="3"
                          placeholder="Enter Note"
                          name="sale_note"
                          value={SaleData.sale_note}
                          onChange={handleSaleData}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col xl={4}>
                  <Row>
                    <Col className="col-12">
                      <Card
                        style={{
                          border: "0",
                          borderRadius: "5px",
                          boxShadow:
                            "0 4px 20px 1px rgba(0,0,0,.06),0 1px 4px rgba(0,0,0,.08)",
                        }}
                      >
                        <CardBody>
                          <div className="table-responsive">
                            <Table className="table table-hover mb-0">
                              <tbody>
                                <tr>
                                  <td>Total Products </td>
                                  <td>
                                    <Badge
                                      color="primary"
                                      style={{ padding: "5px" }}
                                    >
                                      {totalQuantity}
                                    </Badge>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Total Amount</td>
                                  <td>RS: {totalPrice}</td>
                                </tr>
                                <tr>
                                  <td>Order Tax</td>
                                  <td>RS: 0.00</td>
                                </tr>
                                <tr>
                                  <td>Discount</td>
                                  <td>RS: 0.00</td>
                                </tr>
                                <tr>
                                  <td>Shipping</td>
                                  <td>RS: 0.00</td>
                                </tr>
                                <tr>
                                  <td>Grand Total</td>
                                  <td>RS: {totalPrice}</td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              {loading ? (
                <button type="button" className="btn btn-dark " disabled>
                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                  Loading
                </button>
              ) : (
                <>
                  <Button color="primary" onClick={handleSubmit} value="submit">
                    Submit
                  </Button>
                  <Button
                    color="primary"
                    onClick={handleSubmitPrint}
                    value="submit_print"
                  >
                    Submit & Print
                  </Button>
                </>
              )}
              <Button type="button" color="secondary" onClick={toggle}>
                Close
              </Button>
            </ModalFooter>
          </Form>
        </div>
      </Modal>
      <SalePaymentInvoice
        show={invoiceModal}
        toggle={InvoiceToggle}
        InvoiceData={selectedProduct}
        totalPrice={totalPrice}
        totalQuantity={totalQuantity}
      />
    </>
  )
}

export default MakePaymentModal
