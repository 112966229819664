import React from "react"
import { Navigate } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Pages Calendar
import Calendar from "../pages/Calendar/index"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

//Pages
import PagesStarter from "../pages/Utility/pages-starter"
import PagesMaintenance from "../pages/Utility/pages-maintenance"
import PagesComingsoon from "../pages/Utility/pages-comingsoon"
import PagesTimeline from "../pages/Utility/pages-timeline"
import PagesFaqs from "../pages/Utility/pages-faqs"
import PagesPricing from "../pages/Utility/pages-pricing"
import Pages404 from "../pages/Utility/pages-404"
import Pages500 from "../pages/Utility/pages-500"

import Dashboard from "../pages/Dashboard/index"

// <------- Milk Shop --------->
//inventory
import AddProducts from "../pages/Inventory/AddProducts"
import AllProducts from "../pages/Inventory/AllProducts"
import ViewProduct from "../pages/Inventory/ViewProduct"

//POS
import ProductList from "pages/POS/productList"
//users
import AllUsers from "pages/Users/allusers"
//Wrehouse
import AllWarehouses from "pages/Warehouses/warehouses"
//Branches
import AllBranches from "pages/Branches/branches"

//Expenses
import AllExpenses from "pages/Expenses/allExpense"
import AddExpense from "pages/Expenses/addExpense"
import AllExpensesCategories from "pages/Expenses/expenseCat"
import EditExpense from "pages/Expenses/viewExpense"

//Sales
import AllSales from "pages/AllSales/allsalesdata"
//Controls
import AllControls from "pages/Controls/controls"

const authProtectedRoutes = [
  //inventory
  { path: "/add-stock-products", component: <AddProducts /> },
  { path: "/all-stock-products", component: <AllProducts /> },
  { path: "/view-product-detail/:viewId", component: <ViewProduct /> },

  //users
  { path: "/all-users", component: <AllUsers /> },

  //warehouse
  { path: "/all-warehouses", component: <AllWarehouses /> },
  //Branches
  { path: "/all-branches", component: <AllBranches /> },
  //Controls
  { path: "/all-controls", component: <AllControls /> },

  //Expense
  { path: "/all-expense", component: <AllExpenses /> },
  { path: "/add-expense", component: <AddExpense /> },
  { path: "/expense-category", component: <AllExpensesCategories /> },
  { path: "/view-expense/:editId", component: <EditExpense /> },

  //Sales
  { path: "/all-sales", component: <AllSales /> },

  { path: "/dashboard", component: <Dashboard /> },

  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },

  // //calendar
  { path: "/calendar", component: <Calendar /> },

  // //profile
  { path: "/profile", component: <UserProfile /> },

  //Utility
  { path: "/pages-starter", component: <PagesStarter /> },
  { path: "/pages-timeline", component: <PagesTimeline /> },
  { path: "/pages-faqs", component: <PagesFaqs /> },
  { path: "/pages-pricing", component: <PagesPricing /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
]

const publicRoutes = [
  //POS
  { path: "/pos-system", component: <ProductList /> },

  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },

  { path: "/pages-maintenance", component: <PagesMaintenance /> },
  { path: "/pages-comingsoon", component: <PagesComingsoon /> },
  { path: "/pages-404", component: <Pages404 /> },
  { path: "/pages-500", component: <Pages500 /> },
]

export { authProtectedRoutes, publicRoutes }
