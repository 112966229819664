import React, { useEffect, useState } from "react"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  Form,
  Input,
} from "reactstrap"
import apiURl from "url"
import axios from "axios"

import toastr from "toastr"
import "toastr/build/toastr.min.css"

const ExpenseCategoryModal = ({
  isOpen1,
  toggle1,
  editId,
  editMode,
  EcatData,
  fetchExpenseCat,
}) => {
  const [Ecategory, setEcategory] = useState("")
  const [loading, setLoading] = useState(false)
  const SubmitCategory = async () => {
    if (Ecategory.length == 0) {
      toastr.error("Please Enter Category Name")
    } else {
      setLoading(true)
      const CatData = new FormData()
      CatData.append("expense_category", Ecategory)
      try {
        const url = editMode
          ? `${apiURl}/api/UpdateExpenseCat/${editId}`
          : `${apiURl}/api/addExpenseCategory`
        const response = await axios.post(url, CatData, {
          headers: {
            "Content-Type": "application/json",
          },
        })

        if (response.data.success === "success") {
          toggle1()
          fetchExpenseCat()
          toastr.success(response.data.message)
          setLoading(false)
          setEcategory("")
        } else {
          toastr.error(response.data.message)
        }
      } catch (error) {
        setLoading(false)
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toastr.error(error.response.data.message)
        } else {
          toastr.error("An unexpected error occurred.")
        }
        console.error("Error:", error)
      } finally {
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    if (EcatData) {
      setEcategory("")
    }
  }, [EcatData])

  useEffect(() => {
    const fetchexpenseCat = async editId => {
      try {
        setLoading(true)
        const response = await axios.get(
          `${apiURl}/api/getExpenseCatById/${editId}`
        )
        const name = response.data.category_name
        setEcategory(name)
      } catch (error) {
        console.error("Error fetching expenseCat:", error)
      } finally {
        setLoading(false)
      }
    }
    if (editId && isOpen1 && editMode) {
      fetchexpenseCat(editId)
    } else {
      setEcategory("")
    }
  }, [editId, editMode, isOpen1])

  return (
    <Modal
      isOpen={isOpen1}
      role="dialog"
      size="md"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle1}
    >
      <div>
        <ModalHeader toggle={toggle1}>
          <h5 className="modal-title" id="exampleModalLabel">
            {editMode ? "Edit Expense Category" : "Add Expense Category"}
          </h5>
        </ModalHeader>
        <Form>
          <ModalBody>
            <Row>
              <div className="mb-3">
                <label>Expense Category</label>
                <Input
                  className="form-control"
                  type="text"
                  placeholder="name"
                  name="expense_category"
                  value={Ecategory}
                  onChange={e => setEcategory(e.target.value)}
                ></Input>
              </div>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="secondary" onClick={toggle1}>
              Close
            </Button>

            {editMode ? (
              loading ? (
                <button type="button" className="btn btn-dark " disabled>
                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                  Loading
                </button>
              ) : (
                <Button color="primary" onClick={SubmitCategory}>
                  Edit Category
                </Button>
              )
            ) : loading ? (
              <button type="button" className="btn btn-dark " disabled>
                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                Loading
              </button>
            ) : (
              <Button color="primary" onClick={SubmitCategory}>
                Submit
              </Button>
            )}
          </ModalFooter>
        </Form>
      </div>
    </Modal>
  )
}

export default ExpenseCategoryModal
