import { useState, useEffect } from "react"
import axios from "axios"
import apiURl from "url"
import userEvent from "@testing-library/user-event"

const useFetchUser = () => {
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(true)

  const fetchUsers = async () => {
    try {
      setLoading(true)
      const response = await axios.get(`${apiURl}/api/getUsers`)
      setUsers(response.data)
    } catch (error) {
      console.error("Error fetching users:", error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchUsers()
  }, [])

  return { users, loading, fetchUsers }
}

export default useFetchUser
