import { useState, useEffect } from "react"
import axios from "axios"
import apiURl from "url"

const useFetchProducts = () => {
  const [allProducts, setallProducts] = useState([])
  const [loading, setLoading] = useState(true)
  const fetchProducts = async () => {
    try {
      setLoading(true)
      const response = await axios.get(`${apiURl}/api/getProducts`)
      setallProducts(response.data)
    } catch (error) {
      console.error("Error fetching products:", error)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    fetchProducts()
  }, [])

  return { allProducts, loading, fetchProducts }
}

export default useFetchProducts
