import { useState, useEffect } from "react"
import axios from "axios"
import apiURl from "url"

const useFetchBranches = () => {
  const [FBranches, setFBranches] = useState([])
  const [FullBranchData, setFullBranchData] = useState([])
  const [loading, setLoading] = useState(true)
  const fetchBranches = async () => {
    try {
      setLoading(true)
      const response = await axios.get(`${apiURl}/api/getBranchData`)
      const Data = response.data
      const BranchName = response.data.map(name => ({
        value: name.Branch_name,
        label: `${name.Branch_name} (${name.Branch_code})`,
      }))
      setFullBranchData(Data)
      setFBranches(BranchName)
    } catch (error) {
      console.error("Error fetching branches:", error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchBranches()
  }, [])
  return { FBranches, FullBranchData, loading, fetchBranches }
}

export default useFetchBranches
