import PropTypes from "prop-types"
import React, { useState, useMemo } from "react"
import { Link, redirect } from "react-router-dom"
import {
  Modal,
  ModalBody,
  Input,
  UncontrolledTooltip,
  Badge,
  Table,
} from "reactstrap"

const HoldData = ({ show, toggle, setUnHoldData, setUnHoldClick }) => {
  const HoldData = JSON.parse(localStorage.getItem("holdData") || "[]")

  //UnHold Data
  const handleUnHold = refNo => {
    toggle()
    const filteredData = HoldData.filter(item => item.RefNo == refNo)
    setUnHoldData(filteredData)
    setUnHoldClick(true)
  }

  //Delete Hold Data
  const DeleteHoldData = id => {
    toggle()
    const deleteData = HoldData.filter(item => item.RefNo !== id)
    localStorage.setItem("holdData", JSON.stringify(deleteData))
  }

  return (
    <Modal size="lg" isOpen={show} toggle={toggle}>
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myLargeModalLabel">
          Hold List
        </h5>
        <button
          onClick={() => toggle()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <Table className="table table-bordered mb-0">
        <thead className="table-light">
          <tr>
            <th>Ref No</th>
            <th>Date</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {HoldData.length !== 0 ? (
            HoldData.map((e, index) => {
              return (
                <tr key={index}>
                  <td>{e.RefNo}</td>
                  <td>{e.timestamp}</td>

                  <td>
                    {" "}
                    <div className="d-flex gap-3">
                      <Link
                        to="#"
                        className="text-primary"
                        onClick={() => handleUnHold(e.RefNo)}
                      >
                        <i
                          className="mdi mdi-circle-edit-outline font-size-18"
                          id="viewtooltip"
                        />
                        <UncontrolledTooltip
                          placement="top"
                          target="viewtooltip"
                        >
                          UnHold
                        </UncontrolledTooltip>
                      </Link>
                      <Link
                        to="#"
                        className="text-danger"
                        onClick={() => DeleteHoldData(e.RefNo)}
                      >
                        <i
                          className="mdi mdi-delete font-size-18"
                          id="deletetooltip"
                        />
                        <UncontrolledTooltip
                          placement="top"
                          target="deletetooltip"
                        >
                          Delete
                        </UncontrolledTooltip>
                      </Link>
                    </div>
                  </td>
                </tr>
              )
            })
          ) : (
            <tr>
              <td colSpan={4} style={{ textAlign: "center" }}>
                No Data In-Hold
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </Modal>
  )
}

HoldData.propTypes = {
  onHoldClick: PropTypes.func,
  show: PropTypes.any,
}

export default HoldData
