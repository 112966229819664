import React, { useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom"
import useFetchUser from "hooks/useFetchusers"
const { v4: uuidv4 } = require("uuid")

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  UncontrolledTooltip,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  InputGroup,
  InputGroupText,
  Modal,
  ModalBody,
  ModalHeader,
  FormFeedback,
  ModalFooter,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import apiURl from "url"

//firabse
// import { storage } from "config/firebase"

//userModal
import UserCreationModal from "components/userModal/userModal"

import toastr from "toastr"
import "toastr/build/toastr.min.css"
import TableContainer from "components/Common/TableContainer"
import DeleteModal from "components/Common/DeleteModal"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"

import { useFormik } from "formik"
import * as Yup from "yup"

import Loader from "hooks/loader"

import { LazyLoadImage } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"

import imgPlaceholder from "hooks/loader"

const AllUsers = () => {
  //meta title
  document.title = "All Usres"
  const { users, loading, fetchUsers } = useFetchUser()
  const [deleteModal, setDeleteModal] = useState(false)

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "user_name",
        disableFilters: true,
        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: "Role",
        accessor: "user_role",
        disableFilters: true,
        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: "City",
        accessor: "user_city",
        disableFilters: true,
        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: "Email",
        accessor: "user_email",
        disableFilters: true,
        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: "Image",
        accessor: "user_img",
        disableFilters: true,
        Cell: cellProps => {
          const imageUrl = cellProps.value

          return (
            <>
              <div className="avatar-group">
                <div className="avatar-group-item">
                  <LazyLoadImage
                    src={imageUrl}
                    className="rounded-circle avatar-xs d-inline-block"
                    alt=""
                    effect="blur"
                    // loading="lazy"
                    placeholder={<imgPlaceholder />}
                  />
                </div>
              </div>
            </>
          )
        },
      },

      {
        Header: "Action",
        accessor: "id",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <>
              <div className="d-flex gap-3">
                <Link
                  to="#"
                  onClick={() => ViewEditModal(cellProps.value)}
                  className="text-primary"
                >
                  <i className="mdi mdi-eye font-size-18" id="viewtooltip" />
                  <UncontrolledTooltip placement="top" target="viewtooltip">
                    View
                  </UncontrolledTooltip>
                </Link>
                <Link
                  to="#"
                  className="text-danger"
                  onClick={() => showDeleteModal(cellProps.value)}
                >
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                  />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </div>
            </>
          )
        },
      },
    ],
    []
  )

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [editId, setEditId] = useState("")
  const [modalData, setModalData] = useState(false)

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
    setModalData(false)
  }

  //Insert New User
  const addUser = () => {
    setIsModalOpen(true)
    setEditMode(false)
    setModalData(true)
    setEditId("")
  }
  //Edit User
  const ViewEditModal = id => {
    setIsModalOpen(true)
    setEditId(id)
    setEditMode(true)
    setModalData(false)
  }

  //Delete User
  const [delid, setDelid] = useState([])
  const showDeleteModal = id => {
    setDelid(id)
    setDeleteModal(true)
  }
  const handleUsersDelete = async () => {
    try {
      const response = await axios.delete(`${apiURl}/api/deleteUsers/${delid}`)

      if (response.data.success === "success") {
        toastr.success(response.data.message)
        setDeleteModal(false)
        fetchUsers() // Refresh the users list
      } else {
        toastr.error("Failed to delete users.")
      }
    } catch (error) {
      console.error("Error:", error)
    }
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleUsersDelete}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcr umb */}
          <Breadcrumbs title="Ecommerce" breadcrumbItem="All Users" />

          <Row className="pt-3">
            <Col lg="12">
              {loading ? (
                <Loader />
              ) : (
                <Card>
                  <CardBody>
                    <Row>
                      <Col>
                        <div className="float-end">
                          <Button
                            type="button"
                            color="primary"
                            className="w-md btn btn-sm"
                            onClick={() => addUser()}
                          >
                            <i className="fa fa-fw fa-plus" />
                            Create User
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    <TableContainer
                      columns={columns}
                      data={users}
                      isGlobalFilter={true}
                      isAddOptions={false}
                      customPageSize={10}
                    />
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
        </Container>
      </div>
      <UserCreationModal
        isOpen={isModalOpen}
        toggle={toggleModal}
        modalData={modalData}
        editMode={editMode}
        {...(editId && { editId })}
        fetchUsers={fetchUsers}
      />
    </React.Fragment>
  )
}

export default AllUsers
