import React, { useEffect, useState } from "react"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  Form,
  CardTitle,
  Input,
  Container,
  InputGroup,
  InputGroupText,
  FormFeedback,
  Label,
  Card,
  CardBody,
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faEyeSlash, faL } from "@fortawesome/free-solid-svg-icons"
import Breadcrumbs from "../../components/Common/Breadcrumb"

import apiURl from "url"
import axios from "axios"
import Select from "react-select"

import toastr from "toastr"
import "toastr/build/toastr.min.css"

//firabse
const { v4: uuidv4 } = require("uuid")
// import { storage } from "config/firebase"
import useFetchWarehouses from "hooks/useFetchWarehouse"
import useFetchExpense from "hooks/useFetchExpsens"
import WareHouseModal from "components/WarehouseModal/houseModal"
import { useNavigate, useParams } from "react-router-dom"
import ExpenseCategoryModal from "components/ExpenseCat/expenseCat"
import useFetchexpenseCat from "hooks/useFetchExpenseCat"
import Loader from "hooks/loader"

const EditExpense = () => {
  const navigator = useNavigate()
  const { editId } = useParams()
  //Edit Mode
  const [editMode, setEditMode] = useState(true)
  const editFields = () => {
    setEditMode(prevState => {
      return !prevState
    })
  }
  //Fetch Expense By Id
  const initialExpenseData = {
    expense_title: "",
    expense_warehouse: "",
    expense_category: "",
    expense_amount: "",
    expense_detail: "",
    expense_date: "",
  }
  const [ExpenseData, setExpenseData] = useState(initialExpenseData)
  const [viewLoading, setViewLoading] = useState(false)
  const fetchExpenseById = async editId => {
    setViewLoading(true)
    try {
      const response = await axios.get(`${apiURl}/api/getExpenseById/${editId}`)
      if (response.data.success == "success") {
        const FExpensedata = response.data
        setExpenseData({
          expense_title: FExpensedata.expense_title || "",
          expense_warehouse: FExpensedata.expense_warehouse || "",
          expense_category: FExpensedata.expense_category || "",
          expense_amount: FExpensedata.expense_amount || "",
          expense_detail: FExpensedata.expense_detail || "",
          expense_date: FExpensedata.expense_date,
        })
        setViewLoading(false)
      }
    } catch (error) {
      console.error("Error fetching products:", error)
    } finally {
      setViewLoading(false)
    }
  }
  useEffect(() => {
    fetchExpenseById(editId)
  }, [editId])

  //Warehouses
  const { FHouse, fetchHouse } = useFetchWarehouses()
  const handleExpenseWarehouse = selectedOption => {
    setExpenseData(prevData => ({
      ...prevData,
      expense_warehouse: selectedOption ? selectedOption.value : "",
    }))
    validation.setFieldValue(
      "expense_warehouse",
      selectedOption ? selectedOption.value : ""
    )
    validation.setFieldTouched("expense_warehouse", true)
  }
  //Add Warehouse
  const [houseModal, setHouseModal] = useState(false)
  const [modalData, setModalData] = useState(false)
  const togglehouseModal = () => {
    setHouseModal(!houseModal)
    setModalData(false)
  }
  const addWareHouse = () => {
    setHouseModal(true)
    setModalData(true)
  }

  //Expense categories
  const { expenseCat, expenseCatOpt, fetchexpenseCat } = useFetchexpenseCat()
  const handleExpenseCategory = selectedOption => {
    setExpenseData(prevData => ({
      ...prevData,
      expense_category: selectedOption ? selectedOption.value : "",
    }))
    validation.setFieldValue(
      "expense_category",
      selectedOption ? selectedOption.value : ""
    )
    validation.setFieldTouched("expense_category", true)
  }

  //Add Expense Category
  const [ECatModal, setECatModal] = useState(false)
  const toggleExpcatModal = () => {
    setECatModal(!ECatModal)
  }
  const AddExpCategory = () => {
    setECatModal(true)
  }

  //Validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: ExpenseData,
    validationSchema: Yup.object({
      expense_title: Yup.string().required("Title is required"),
      expense_date: Yup.string().required("Date is required"),
      expense_warehouse: Yup.string().required("Warehouse is required"),
      expense_category: Yup.string().required("Category is required"),
      expense_amount: Yup.string().required("Amount is required"),
    }),
    onSubmit: values => {
      console.log(values)
    },
  })

  //Submittion
  const handleButtonClick = async () => {
    try {
      const isValid = await validation.validateForm()
      if (Object.keys(isValid).length === 0) {
        handleSubmit()
      } else {
        toastr.error("Please fill out all required fields correctly.")
      }
    } catch (error) {
      console.error("Error during form validation:", error)
    }
  }
  const handleExpenseData = e => {
    const { name, value } = e.target

    setExpenseData(prevData => ({
      ...prevData,
      [name]: value,
    }))
  }
  const [loading, setLoading] = useState(false)

  const handleSubmit = async () => {
    // e.preventDefault();
    setLoading(true)

    const EData = new FormData()
    EData.append("expense_title", ExpenseData.expense_title)
    EData.append("expense_date", ExpenseData.expense_date)
    EData.append("expense_warehouse", ExpenseData.expense_warehouse)
    EData.append("expense_category", ExpenseData.expense_category)
    EData.append("expense_amount", ExpenseData.expense_amount)
    EData.append("expense_detail", ExpenseData.expense_detail)

    try {
      const url = `${apiURl}/api/UpdateExpenseByID/${editId}`
      const response = await axios.post(url, EData, {
        headers: {
          "Content-Type": "application/json",
        },
      })

      if (response.data.success === "success") {
        toastr.success(response.data.message)
        setLoading(false)
        navigator("/all-expense")
      } else {
        toastr.error("Failed to add expense.")
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toastr.error(error.response.data.message)
      } else {
        toastr.error("An unexpected error occurred.")
      }
      console.error("Error:", error)
    } finally {
      setLoading(false)
    }
  }

  //close
  const closetab = () => {
    navigator("/all-expense")
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Ecommerce" breadcrumbItem="Add Expense" />
          <Row>
            {viewLoading ? (
              <Loader />
            ) : (
              <Col xs="12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col className="md-6">
                        <CardTitle>Basic Information</CardTitle>
                        <p className="card-title-desc mb-4">
                          Fill all information below
                        </p>
                      </Col>
                      <Col className="md-6">
                        <div className="float-end">
                          <input
                            className={
                              editMode ? "btn btn-danger" : "btn btn-primary"
                            }
                            type="button"
                            value={editMode ? "Edit" : "In Edit-Mode"}
                            onClick={editFields}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="col-6">
                        <div className="mb-3">
                          <label>Expense Title*</label>
                          <Input
                            className="form-control"
                            type="text"
                            placeholder="Title"
                            name="expense_title"
                            value={ExpenseData.expense_title}
                            onChange={handleExpenseData}
                            onBlur={validation.handleBlur}
                            disabled={editMode}
                            invalid={
                              validation.touched.expense_title &&
                              validation.errors.expense_title
                                ? true
                                : false
                            }
                          />

                          {validation.touched.expense_title &&
                            validation.errors.expense_title && (
                              <FormFeedback type="invalid">
                                {validation.errors.expense_title}
                              </FormFeedback>
                            )}
                        </div>
                        <div className="mb-3">
                          <Label
                            className="control-label"
                            htmlFor="Product_warehouse"
                          >
                            WareHouse*
                          </Label>
                          {!editMode && (
                            <span
                              style={{ color: "blue", cursor: "pointer" }}
                              onClick={addWareHouse}
                            >
                              {" "}
                              (Add More+){" "}
                            </span>
                          )}

                          <Select
                            defaultValue="0"
                            className={`select2-selection ${
                              validation.touched.expense_warehouse &&
                              validation.errors.expense_warehouse
                                ? "is-invalid"
                                : ""
                            }`}
                            onChange={handleExpenseWarehouse}
                            onBlur={() =>
                              validation.setFieldTouched(
                                "expense_warehouse",
                                true
                              )
                            }
                            value={FHouse?.find(
                              option =>
                                option.value === ExpenseData.expense_warehouse
                            )}
                            options={[
                              ...(FHouse.length > 0
                                ? FHouse
                                : [{ value: "", label: "No data Found" }]),
                            ]}
                            isDisabled={editMode}
                          />
                          {validation.touched.expense_warehouse &&
                            validation.errors.expense_warehouse && (
                              <FormFeedback type="invalid">
                                {validation.errors.expense_warehouse}
                              </FormFeedback>
                            )}
                        </div>
                        <div className="mb-3">
                          <label>Expense Amount*</label>
                          <Input
                            className="form-control"
                            disabled={editMode}
                            type="text"
                            placeholder="Amount"
                            name="expense_amount"
                            value={ExpenseData.expense_amount}
                            onChange={handleExpenseData}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.expense_amount &&
                              validation.errors.expense_amount
                                ? true
                                : false
                            }
                          />

                          {validation.touched.expense_amount &&
                            validation.errors.expense_amount && (
                              <FormFeedback type="invalid">
                                {validation.errors.expense_amount}
                              </FormFeedback>
                            )}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="expense_detail">Expense detail</Label>
                          <textarea
                            className="form-control mb-3"
                            id="expense_detail"
                            name="expense_detail"
                            rows="5"
                            disabled={editMode}
                            placeholder="Product Description"
                            value={ExpenseData.expense_detail}
                            onChange={handleExpenseData}
                            onBlur={validation.handleBlur}
                          />
                          {validation.touched.expense_detail &&
                            validation.errors.expense_detail && (
                              <FormFeedback type="invalid">
                                {validation.errors.expense_detail}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                      <Col className="col-6">
                        <div className="mb-3">
                          <label>Date</label>
                          <Input
                            className="form-control"
                            type="date"
                            disabled={editMode}
                            placeholder="city"
                            name="expense_date"
                            value={ExpenseData.expense_date}
                            onChange={handleExpenseData}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.expense_date &&
                              validation.errors.expense_date
                                ? true
                                : false
                            }
                          />

                          {validation.touched.expense_date &&
                            validation.errors.expense_date && (
                              <FormFeedback type="invalid">
                                {validation.errors.expense_date}
                              </FormFeedback>
                            )}
                        </div>
                        <div className="mb-3">
                          <Label
                            className="control-label"
                            htmlFor="Product_warehouse"
                          >
                            Expense Category*
                          </Label>
                          {!editMode && (
                            <span
                              style={{ color: "blue", cursor: "pointer" }}
                              onClick={AddExpCategory}
                            >
                              {" "}
                              (Add More+){" "}
                            </span>
                          )}

                          <Select
                            defaultValue="0"
                            className={`select2-selection ${
                              validation.touched.expense_category &&
                              validation.errors.expense_category
                                ? "is-invalid"
                                : ""
                            }`}
                            disabled={editMode}
                            onChange={handleExpenseCategory}
                            onBlur={() =>
                              validation.setFieldTouched(
                                "expense_category",
                                true
                              )
                            }
                            value={expenseCatOpt?.find(
                              option =>
                                option.value === ExpenseData.expense_category
                            )}
                            options={[
                              ...(expenseCatOpt.length > 0
                                ? expenseCatOpt
                                : [{ value: "", label: "No data Found" }]),
                            ]}
                            isDisabled={editMode}
                          />
                          {validation.touched.expense_category &&
                            validation.errors.expense_category && (
                              <FormFeedback type="invalid">
                                {validation.errors.expense_category}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Row className="mb-3">
                  <Col className="md-6">
                    <Button
                      onClick={closetab}
                      color="danger"
                      className="btn btn-danger "
                    >
                      Close
                    </Button>
                  </Col>
                  {!editMode && (
                    <Col className="md-6">
                      <div className="float-end d-flex flex-wrap gap-2">
                        {loading ? (
                          <button
                            type="button"
                            className="btn btn-dark "
                            disabled
                          >
                            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                            Loading
                          </button>
                        ) : (
                          <Button
                            onClick={handleButtonClick}
                            color="primary"
                            className="btn btn-primary "
                          >
                            Save Changes
                          </Button>
                        )}
                      </div>
                    </Col>
                  )}
                </Row>
              </Col>
            )}
          </Row>
        </Container>
      </div>
      <WareHouseModal
        isOpen={houseModal}
        toggle={togglehouseModal}
        modalData={modalData}
        fetchHouse={fetchHouse}
      />
      <ExpenseCategoryModal
        isOpen1={ECatModal}
        toggle1={toggleExpcatModal}
        fetchExpenseCat={fetchexpenseCat}
      />
    </React.Fragment>
  )
}

export default EditExpense
