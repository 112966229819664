import { useState, useEffect } from "react"
import axios from "axios"
import apiURl from "url"

const useFetchCustomer = () => {
  const [Customer, setCustomer] = useState([])
  const [loading, setLoading] = useState(true)
  const [customerOpt, setCustomerOpt] = useState([])

  const fetchCustomer = async () => {
    try {
      setLoading(true)
      const response = await axios.get(`${apiURl}/api/getCustomerData`)
      setCustomer(response.data)
      const CustomerName = response.data.map(name => ({
        value: name.customer_code,
        label: `${name.customer_name} (${name.customer_code})`,
        // label: name.customer_name,
      }))
      setCustomerOpt(CustomerName)
    } catch (error) {
      console.error("Error fetching Customer:", error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchCustomer()
  }, [])

  return { customerOpt, Customer, loading, fetchCustomer }
}

export default useFetchCustomer
