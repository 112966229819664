import { useState, useEffect } from "react"
import axios from "axios"
import apiURl from "url"

const useFetchProductCat = () => {
  const [productCategory, setProductCategory] = useState([])
  const [AllProductCategories, setAllProductCategories] = useState([])
  const [Catloading, setCatLoading] = useState(true)
  const fetchProductsCategory = async () => {
    try {
      setCatLoading(true)
      const response = await axios.get(`${apiURl}/api/getProductCategory`)
      setAllProductCategories(response.data)
      const Categories = response.data.map(name => ({
        value: name.product_category,
        label: name.product_category,
      }))
      setProductCategory(Categories)
    } catch (error) {
      console.error("Error fetching categories:", error)
    } finally {
      setCatLoading(false)
    }
  }
  useEffect(() => {
    fetchProductsCategory()
  }, [])

  return {
    AllProductCategories,
    productCategory,
    Catloading,
    fetchProductsCategory,
  }
}

const useFetchProductUnit = () => {
  const [productUnit, setProductUnit] = useState([])
  const [AllProductUnit, setAllProductUnit] = useState([])
  const [Unitloading, setUnitloading] = useState(true)
  const fetchProductsUnit = async () => {
    try {
      setUnitloading(true)
      const response = await axios.get(`${apiURl}/api/getProductUnit`)
      setAllProductUnit(response.data)
      const unit = response.data.map(name => ({
        value: name.product_unit,
        label: name.product_unit,
      }))
      setProductUnit(unit)
    } catch (error) {
      console.error("Error fetching unit:", error)
    } finally {
      setUnitloading(false)
    }
  }
  useEffect(() => {
    fetchProductsUnit()
  }, [])

  return {
    AllProductUnit,
    productUnit,
    Unitloading,
    fetchProductsUnit,
  }
}

export { useFetchProductCat, useFetchProductUnit }
