import React, { useEffect, useState } from "react"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  FormFeedback,
  Label,
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import apiURl from "url"
import axios from "axios"

import toastr from "toastr"
import "toastr/build/toastr.min.css"

const CategoryModal = ({ isOpen1, toggle1, fetchPrdCat }) => {
  const [Pcategory, setPcategory] = useState("")
  const [loading, setLoading] = useState(false)
  const SubmitCategory = async () => {
    if (Pcategory.length == 0) {
      toastr.error("Please Enter Category Name")
    } else {
      setLoading(true)
      const CatData = new FormData()
      CatData.append("product_category", Pcategory)
      try {
        const url = `${apiURl}/api/addProductCategory`
        const response = await axios.post(url, CatData, {
          headers: {
            "Content-Type": "application/json",
          },
        })

        if (response.data.success === "success") {
          toggle1()
          fetchPrdCat()
          toastr.success(response.data.message)
          setLoading(false)
          setPcategory("")
        } else {
          toastr.error(response.data.message)
        }
      } catch (error) {
        setLoading(false)
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toastr.error(error.response.data.message)
        } else {
          toastr.error("An unexpected error occurred.")
        }
        console.error("Error:", error)
      } finally {
        setLoading(false)
      }
    }
  }

  return (
    <Modal
      isOpen={isOpen1}
      role="dialog"
      size="md"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle1}
    >
      <div>
        <ModalHeader toggle={toggle1}>
          <h5 className="modal-title" id="exampleModalLabel">
            Add Category
          </h5>
        </ModalHeader>
        <Form>
          <ModalBody>
            <Row>
              <div className="mb-3">
                <label>Product Category</label>
                <Input
                  className="form-control"
                  type="text"
                  placeholder="category"
                  name="product_category"
                  value={Pcategory}
                  onChange={e => setPcategory(e.target.value)}
                ></Input>
              </div>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="secondary" onClick={toggle1}>
              Close
            </Button>
            {loading ? (
              <button type="button" className="btn btn-dark " disabled>
                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                Loading
              </button>
            ) : (
              <Button color="primary" onClick={SubmitCategory}>
                Submit
              </Button>
            )}
          </ModalFooter>
        </Form>
      </div>
    </Modal>
  )
}
const UnitModal = ({ isOpen2, toggle2, fetchPrdUnit }) => {
  const [Punit, setPUnit] = useState("")
  const [loading2, setLoading2] = useState(false)
  const SubmitUnit = async () => {
    if (Punit.length == 0) {
      toastr.error("Please Enter Unit Name")
    } else {
      setLoading2(true)
      const UnitData = new FormData()
      UnitData.append("product_unit", Punit)
      try {
        const url = `${apiURl}/api/addProductUnit`
        const response = await axios.post(url, UnitData, {
          headers: {
            "Content-Type": "application/json",
          },
        })

        if (response.data.success === "success") {
          toggle2()
          fetchPrdUnit()
          toastr.success(response.data.message)
          setLoading2(false)
          setPUnit("")
        } else {
          toastr.error(response.data.message)
        }
      } catch (error) {
        setLoading2(false)
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toastr.error(error.response.data.message)
        } else {
          toastr.error("An unexpected error occurred.")
        }
        console.error("Error:", error)
      } finally {
        setLoading2(false)
      }
    }
  }

  return (
    <Modal
      isOpen={isOpen2}
      role="dialog"
      size="md"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle2}
    >
      <div>
        <ModalHeader toggle={toggle2}>
          <h5 className="modal-title" id="exampleModalLabel">
            Add Unit
          </h5>
        </ModalHeader>
        <Form>
          <ModalBody>
            <Row>
              <div className="mb-3">
                <label>Product Unit</label>
                <Input
                  className="form-control"
                  type="text"
                  placeholder="unit"
                  name="product_unit"
                  value={Punit}
                  onChange={e => setPUnit(e.target.value)}
                ></Input>
              </div>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="secondary" onClick={toggle2}>
              Close
            </Button>
            {loading2 ? (
              <button type="button" className="btn btn-dark " disabled>
                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                Loading
              </button>
            ) : (
              <Button color="primary" onClick={SubmitUnit}>
                Submit
              </Button>
            )}
          </ModalFooter>
        </Form>
      </div>
    </Modal>
  )
}

export { CategoryModal, UnitModal }
