import React, { useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom"

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  UncontrolledTooltip,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import apiURl from "url"

import toastr from "toastr"
import "toastr/build/toastr.min.css"
import TableContainer from "components/Common/TableContainer"
import DeleteModal from "components/Common/DeleteModal"
import Loader from "hooks/loader"

import { LazyLoadImage } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"

import imgPlaceholder from "hooks/loader"
import useFetchProducts from "hooks/useFetchProducts"

const AllProducts = () => {
  //meta title
  document.title = "All PRODUCT"

  const [deleteModal, setDeleteModal] = useState(false)
  const { allProducts, loading, fetchProducts } = useFetchProducts()

  //   const [ProductData, setProductData] = useState({
  //     Product_Name:'',
  //     Product_Cat:'',
  //     Product_Price:'',
  //     Product_Des:'',
  //   })

  //   const handleProductData = (e) => {
  //     const {name, value} = e.target
  //     setProductData(prevData => ({
  //       ...prevData,
  //       [name]:value
  //     }))
  //   }

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "Product_Name",
        disableFilters: true,
        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: "Price",
        accessor: "Product_Price",
        disableFilters: true,
        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: "Category",
        accessor: "Product_Cat",
        disableFilters: true,
        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: "In-Stock",
        accessor: "Product_quantity",
        disableFilters: true,
        Cell: cellProps => {
          return <>{cellProps.value} P</>
        },
      },
      {
        Header: "Description",
        accessor: "Product_Des",
        disableFilters: true,
        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: "Images",
        accessor: "Product_Images",
        disableFilters: true,
        Cell: cellProps => {
          const imageUrl = cellProps.value
          return (
            <>
              <div className="avatar-group">
                <div className="avatar-group-item">
                  <LazyLoadImage
                    src={imageUrl}
                    className="rounded-circle avatar-xs d-inline-block"
                    alt=""
                    effect="blur"
                    // loading="lazy"
                    placeholder={<imgPlaceholder />}
                  />
                </div>
              </div>
            </>
          )
        },
      },
      {
        Header: "Action",
        accessor: "id",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <>
              <div className="d-flex gap-3">
                <Link
                  to={`/view-product-detail/${cellProps.value}`}
                  className="text-primary"
                >
                  <i className="mdi mdi-eye font-size-18" id="viewtooltip" />
                  <UncontrolledTooltip placement="top" target="viewtooltip">
                    View
                  </UncontrolledTooltip>
                </Link>
                <Link
                  to="#"
                  className="text-danger"
                  onClick={() => showDeleteModal(cellProps.value)}
                >
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                  />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </div>
            </>
          )
        },
      },
    ],
    []
  )

  const [delid, setDelid] = useState([])
  const showDeleteModal = id => {
    setDelid(id)
    setDeleteModal(true)
  }

  const handleProductDelete = async () => {
    try {
      const response = await axios.delete(
        `${apiURl}/api/deleteProduct/${delid}`
      )

      if (response.data.success === "success") {
        toastr.success(response.data.message)
        setDeleteModal(false)
        fetchProducts() // Refresh the product list
      } else {
        toastr.error("Failed to delete product.")
      }
    } catch (error) {
      console.error("Error:", error)
    }
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleProductDelete}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcr umb */}
          <Breadcrumbs title="Ecommerce" breadcrumbItem="All Products" />

          <Row className="pt-3">
            <Col lg="12">
              {loading ? (
                <Loader />
              ) : (
                <Card>
                  <CardBody>
                    <Row>
                      <Col>
                        <div className="float-end">
                          <Link to={"/add-stock-products"}>
                            <Button
                              type="button"
                              color="primary"
                              className="w-md btn btn-sm"
                            >
                              <i className="fa fa-fw fa-plus" />
                              Add Product
                            </Button>
                          </Link>
                        </div>
                      </Col>
                    </Row>
                    <TableContainer
                      columns={columns}
                      data={allProducts}
                      isGlobalFilter={true}
                      isAddOptions={false}
                      customPageSize={10}
                    />
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AllProducts
