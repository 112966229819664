import { useState, useEffect } from "react"
import axios from "axios"
import apiURl from "url"
import userEvent from "@testing-library/user-event"

const useFetchexpenseCat = () => {
  const [expenseCat, setexpenseCat] = useState([])
  const [expenseCatOpt, setexpenseCatOpt] = useState([])
  const [loading, setLoading] = useState(true)

  const fetchexpenseCat = async () => {
    try {
      setLoading(true)
      const response = await axios.get(`${apiURl}/api/getexpenseCat`)
      const CategoryName = response.data.map(name => ({
        value: name.category_name,
        label: name.category_name,
      }))
      setexpenseCat(response.data)
      setexpenseCatOpt(CategoryName)
    } catch (error) {
      console.error("Error fetching expenseCat:", error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchexpenseCat()
  }, [])

  return { expenseCatOpt, expenseCat, loading, fetchexpenseCat }
}

export default useFetchexpenseCat
