// Loader.js
import React from "react"
import { TailSpin } from "react-loader-spinner"

const Loader = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "50vh",
    }}
  >
    <TailSpin height="50" width="80" color="#556EE6" ariaLabel="loading" />
  </div>
)

export default Loader
