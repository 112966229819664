// src/components/ProductList.js
import React, { useEffect, useState, globalFilter } from "react"
import "../../assets/style/style.css"
import { json, Link, useNavigate } from "react-router-dom"
import NoData from "../../assets/images/No_Product_Found.png"
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Input,
  Table,
  CardHeader,
  Label,
  CardTitle,
  Button,
  Spinner,
  InputGroup,
  UncontrolledTooltip,
} from "reactstrap"
import Loader from "hooks/loader"
import { LazyLoadImage } from "react-lazy-load-image-component"
import useFetchProducts from "hooks/useFetchProducts"
import { useFetchProductCat } from "hooks/useFetchControlls"
import { all } from "redux-saga/effects"
const selectSound = new Audio("/select-sound.wav")
import Select from "react-select"
import useFetchWarehouses from "hooks/useFetchWarehouse"
import CustomerCreationModal from "components/CustomerModal/customerModal"
import useFetchCustomer from "hooks/useFetchCustomers"
import { reset } from "redux-form"
import HoldModal from "./holdModal"
import HoldData from "./holdData"
import currentDate from "pages/Functions/functions"

import toastr from "toastr"
import "toastr/build/toastr.min.css"
import MakePaymentModal from "./paymentModal"

const ProductList = () => {
  const LocalHoldData = JSON.parse(localStorage.getItem("holdData")) || []
  const navigate = useNavigate()
  const [selectedProduct, setSelectedProduct] = useState([])
  const { allProducts, loading, fetchProducts } = useFetchProducts()
  const { AllProductCategories } = useFetchProductCat()

  const toDashboard = () => {
    navigate("/dashboard")
    toggleFullscreen()
  }

  const toggleFullscreen = () => {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  useEffect(() => {
    // tToggle()
    toggleFullscreen()
  }, [])

  const onSelect = product => {
    selectSound.play()
    setSelectedProduct(prevSelectedProducts => {
      if (prevSelectedProducts.some(p => p.id === product.id)) {
        return prevSelectedProducts.filter(p => p.id !== product.id)
      } else {
        return [...prevSelectedProducts, { ...product, quantity: 1 }]
      }
    })
  }

  const countUP = id => {
    selectSound.play()
    setSelectedProduct(
      selectedProduct.map(p =>
        p.id === id ? { ...p, quantity: p.quantity + 1 } : p
      )
    )
  }

  const countDown = id => {
    selectSound.play()
    setSelectedProduct(
      selectedProduct.map(p =>
        p.id === id && p.quantity > 1 ? { ...p, quantity: p.quantity - 1 } : p
      )
    )
  }

  const removeCartItem = id => {
    setSelectedProduct(selectedProduct.filter(item => item.id !== id))
  }

  const calculateTotal = product => {
    return (product.Product_Price * product.quantity).toFixed(2)
  }

  const totalQuantity = selectedProduct.reduce(
    (acc, product) => acc + product.quantity,
    0
  )

  const totalPrice = selectedProduct
    .reduce((acc, product) => acc + product.Product_Price * product.quantity, 0)
    .toFixed(2)

  //Handle Customer
  const { customerOpt, fetchCustomer } = useFetchCustomer()
  const [OpenCustomerModal, setOpenCustomerModal] = useState(false)
  const [selectedCustomer, setSelectedCustomer] = useState("walk-in")
  const defaultCustomerOption = {
    value: "walk-in",
    label: "Walk-in-customer",
  }

  // Initialize customerOptions state
  const [customerOptions, setCustomerOptions] = useState([
    { value: "walk-in", label: "Walk-in-customer" },
    ...(customerOpt.length > 0
      ? customerOpt
      : [{ value: "", label: "No data Found" }]),
  ])

  const toggleCustomerModal = () => {
    setOpenCustomerModal(!OpenCustomerModal)
  }
  const addCustomer = () => {
    setOpenCustomerModal(true)
  }
  const handleCustomer = option => {
    setSelectedCustomer(option ? option.value : "")
  }

  // Update customer options when customerOpt changes
  useEffect(() => {
    const updatedCustomerOptions = [
      defaultCustomerOption,
      ...(customerOpt.length > 0
        ? customerOpt
        : [{ value: "", label: "No data Found" }]),
    ]
    setCustomerOptions(updatedCustomerOptions)
  }, [customerOpt])

  const handleNewCustomer = newCustomer => {
    // Manually add the new customer to the options list
    setCustomerOptions(prevOptions => [...prevOptions, newCustomer])
    // console.log(newCustomer.value)
    setSelectedCustomer(newCustomer.value)
  }

  //Handle Product through Warehouse
  const { FHouse, fetchHouse } = useFetchWarehouses()
  const [selectedWarehouse, setSelectedWarehouses] = useState([])

  const defaultWarehouseOption = { value: "", label: "Select Warehouse ..." }

  const WarehouseOption = [
    defaultWarehouseOption,
    ...(FHouse.length > 0 ? FHouse : [{ value: "", label: "No data Found" }]),
  ]

  const handleProductWarehouse = Soption => {
    setSelectedWarehouses(Soption ? Soption.value : "")
  }

  //Hanlde Product through category
  const [selectCategory, setSelectCategory] = useState([])
  const [filteredProducts, setFilteredProducts] = useState(allProducts)
  let count = filteredProducts.length

  const handleProducts = category => {
    selectSound.play()
    setSelectCategory(prevState => {
      if (prevState.some(cat => cat.product_category === category)) {
        return prevState.filter(cat => cat.product_category !== category)
      } else {
        return [...prevState, { product_category: category }]
      }
    })
  }

  const showAllProducts = () => {
    setSelectCategory([])
  }
  //Reset
  const Reset = () => {
    setSelectedProduct([])
    setSelectCategory([])
    setSelectedWarehouses(defaultWarehouseOption.value)
    setSelectedCustomer(defaultCustomerOption.value)
    localStorage.removeItem("holdData")
  }
  //css
  const customStyles = {
    menu: provided => ({
      ...provided,
      zIndex: 9999, // Ensure the dropdown appears above other elements
      backgroundColor: "white", // Set the background color for the dropdown menu
    }),
  }

  //Search
  const [SearchPrd, setSearchPrd] = useState("")
  const handleSearchChange = event => {
    setSearchPrd(event.target.value)
  }
  useEffect(() => {
    const search = allProducts.filter(product =>
      product.Product_Name.toLowerCase().includes(SearchPrd.toLowerCase())
    )
    setFilteredProducts(search)
  }, [allProducts, SearchPrd])

  //Logic
  useEffect(() => {
    if (!loading) {
      let filtered = allProducts

      // Filter by categories
      if (selectCategory.length > 0) {
        filtered = filtered.filter(product =>
          selectCategory.some(
            cat => cat.product_category === product.Product_Cat
          )
        )
      }

      // Filter by warehouse
      if (selectedWarehouse.length > 0) {
        filtered = filtered.filter(
          product => selectedWarehouse === product.Product_warehouse
        )
      }

      if (selectCategory.length > 0 && selectedWarehouse.length > 0) {
        filtered = filtered.filter(
          product =>
            selectCategory.some(
              cat => cat.product_category === product.Product_Cat
            ) && selectedWarehouse === product.Product_warehouse
        )
      }

      // Filter by search query
      if (SearchPrd) {
        filtered = filtered.filter(product =>
          product.Product_Name.toLowerCase().includes(SearchPrd.toLowerCase())
        )
      }

      setFilteredProducts(filtered)
    }
  }, [allProducts, selectCategory, loading, selectedWarehouse, SearchPrd])

  //HoldSale
  const [holdModal, setHoldModal] = useState(false)
  const [holdRefNo, setHoldRefNo] = useState("")
  const [countHold, setCountHold] = useState(0)

  const holdSaleClick = () => {
    setHoldModal(true)
  }

  const handleHoldSale = () => {
    if (holdRefNo.length !== 0) {
      const HoldDetail = {
        RefNo: holdRefNo,
        Warehouse: selectedWarehouse,
        Customer: selectedCustomer,
        products: selectedProduct,
        timestamp: currentDate,
      }
      const exsitingData = JSON.parse(localStorage.getItem("holdData")) || []
      const upDatedData = exsitingData.filter(item => item.RefNo !== holdRefNo)
      upDatedData.push(HoldDetail)
      localStorage.setItem("holdData", JSON.stringify(upDatedData))
      setSelectedProduct([])
      setSelectedWarehouses("")
      setSelectedCustomer(defaultCustomerOption.value)
      setHoldModal(false)
      setHoldRefNo("")
    } else {
      toastr.error("Please enter a valid reference number")
    }
  }

  useEffect(() => {
    const exsitingData = JSON.parse(localStorage.getItem("holdData")) || []
    setCountHold(exsitingData.length)
  }, [handleHoldSale])

  //UnHold Sale
  const [unHoldData, setUnHoldData] = useState([])
  const [unHoldClick, setUnHoldClick] = useState(false)
  const [unHoldRefNo, setunHoldRefNo] = useState("")

  useEffect(() => {
    if (unHoldClick && unHoldData.length !== 0) {
      setSelectedProduct(unHoldData.flatMap(item => item.products))
      unHoldData.map(item => {
        setSelectedCustomer(item.Customer ? item.Customer : "")
      })
      unHoldData.map(item => {
        setSelectedWarehouses(item.Warehouse ? item.Warehouse : "")
      })
      unHoldData.map(item => {
        setunHoldRefNo(item.RefNo ? item.RefNo : "")
      })

      setUnHoldClick(false)
    }
  }, [unHoldClick, unHoldData])

  //Get Hold Data
  const [holdDataModal, setHoldDataModal] = useState(false)
  const getItem = () => {
    setHoldDataModal(true)
  }
  const toggleHoldData = () => {
    setHoldDataModal(!holdDataModal)
  }

  //Make Payment
  const [makePayment, setMakePayment] = useState(false)
  const MakePayment = () => {
    setMakePayment(true)
  }

  const togglePaymentModal = () => {
    setMakePayment(!makePayment)
  }

  //Del Paid hold Sale
  const [ResetHold, setResetHold] = useState(false)
  const [DirectSale, setDirectSale] = useState(false)
  useEffect(() => {
    if (ResetHold) {
      const deleteData = LocalHoldData.filter(
        item => item.RefNo !== unHoldRefNo
      )
      localStorage.setItem("holdData", JSON.stringify(deleteData))
      setSelectedProduct([])
      setResetHold(false)
      setSelectedWarehouses("")
      setSelectedCustomer(defaultCustomerOption.value)
      fetchProducts()
    } else if (DirectSale) {
      const existingData = JSON.parse(localStorage.getItem("holdData")) || []
      localStorage.setItem("holdData", JSON.stringify(existingData))
      setSelectedProduct([])
      setDirectSale(false)
      setSelectedWarehouses("")
      setSelectedCustomer(defaultCustomerOption.value)
      fetchProducts()
    }
  }, [ResetHold, DirectSale])

  //All data
  const AllSaleData = Array.isArray(selectedProduct)
    ? selectedProduct.map(products => ({
        ...products,
        selectedWarehouse,
        selectedCustomer,
        totalQuantity,
        totalPrice,
        currentDate,
      }))
    : [
        {
          ...selectedProduct,
          selectedWarehouse,
          selectedCustomer,
          totalQuantity,
          totalPrice,
          currentDate,
        },
      ]

  return (
    <React.Fragment>
      <HoldModal
        show={holdModal}
        onHoldClick={handleHoldSale}
        onCloseClick={() => setHoldModal(false)}
        HoldRefNo={setHoldRefNo}
      />
      <HoldData
        show={holdDataModal}
        toggle={toggleHoldData}
        setUnHoldData={setUnHoldData}
        setUnHoldClick={setUnHoldClick}
      />
      <div className="mt-3">
        <Container fluid>
          <Row>
            <Col md={1}>
              <div className="d-flex flex-wrap gap-2 ">
                <button
                  type="button"
                  className="btn btn-warning position-relative"
                  onClick={getItem}
                >
                  <i
                    className="bx bx-list-ol font-size-20 align-middle"
                    id="hold_list"
                  />
                  <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success">
                    {countHold}
                  </span>
                  <UncontrolledTooltip placement="top" target="hold_list">
                    Hold List
                  </UncontrolledTooltip>
                </button>
                <button
                  type="button"
                  className="btn btn-primary position-relative"
                  onClick={toDashboard}
                >
                  <i
                    className="bx bx-layout font-size-20 align-middle"
                    id="dashboard"
                  />
                  <UncontrolledTooltip placement="top" target="dashboard">
                    Dashboard
                  </UncontrolledTooltip>
                </button>
              </div>
            </Col>
            <Col md={5}>
              <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block ">
                <div className="position-relative">
                  <label htmlFor="search-bar-0" className="search-label">
                    <span id="search-bar-0-label" className="sr-only">
                      Search this table
                    </span>
                    <input
                      onChange={handleSearchChange}
                      id="search-bar-0"
                      type="text"
                      className="form-control"
                      placeholder={`${count} products...`}
                      value={SearchPrd || ""}
                      style={{ width: "200%" }}
                    />
                  </label>
                  <i className="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </Col>

            <Col sm="6">
              <div className="d-flex flex-wrap gap-2 float-end">
                <div className="input-group gap-1">
                  <i
                    className="bx bx-store font-size-20 bold"
                    style={{ alignContent: "center" }}
                  />
                  <Select
                    defaultValue="0"
                    className="select2-selection"
                    placeholder="Select Warehouse ..."
                    onChange={handleProductWarehouse}
                    value={WarehouseOption.find(
                      option => option.value === selectedWarehouse
                    )}
                    options={WarehouseOption}
                    styles={customStyles}
                  />
                  <i
                    className="bx bx-user font-size-20 bold"
                    style={{ alignContent: "center" }}
                  />
                  <Select
                    className="select2-selection"
                    onChange={handleCustomer}
                    value={customerOptions.find(
                      option => option.value === selectedCustomer
                    )}
                    options={customerOptions}
                    styles={customStyles}
                  />
                  <button
                    className="btn btn-primary"
                    type="button"
                    id="inputGroupFileAddon04"
                    onClick={addCustomer}
                  >
                    +Add
                  </button>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col lg={8}>
              {loading ? (
                <Loader />
              ) : (
                <>
                  <div>
                    <Row className="mb-3">
                      <div>
                        <CardTitle>Categories</CardTitle>
                      </div>
                      <Col>
                        {AllProductCategories.length > 0 ? (
                          <div className="d-flex flex-wrap gap-2">
                            <Button color="success" onClick={showAllProducts}>
                              All Categories
                            </Button>
                            {AllProductCategories.map((category, index) => (
                              <>
                                <Button
                                  key={index}
                                  type="button"
                                  color="primary"
                                  onClick={() =>
                                    handleProducts(category.product_category)
                                  }
                                  className={`CategoryBtn ${
                                    selectCategory.some(
                                      cat =>
                                        cat.product_category ===
                                        category.product_category
                                    )
                                      ? "SelectedCat"
                                      : ""
                                  }`}
                                  outline
                                >
                                  {category.product_category}
                                </Button>
                              </>
                            ))}
                          </div>
                        ) : (
                          <Spinner />
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <CardTitle>Products</CardTitle>
                      {filteredProducts.length > 0 ? (
                        filteredProducts.map((product, index) => (
                          <>
                            <Col lg={3} key={index}>
                              <Card
                                onClick={() => onSelect(product)}
                                className={`product2 ${
                                  selectedProduct.some(p => p.id === product.id)
                                    ? "selected"
                                    : ""
                                }`}
                              >
                                <CardBody className="p-4">
                                  {/* <div className="d-flex align-start mb-3">
                                    <div className="flex-grow-1">
                                      <span className="badge badge-soft-success">
                                        sale
                                      </span>
                                    </div>
                                  </div> */}

                                  <div className="text-center mb-3">
                                    <div className="image-bg mb-2">
                                      <LazyLoadImage
                                        src={product.Product_Images}
                                        alt={product.Product_Name}
                                        className="avatar-sm"
                                        effect="blur"
                                      />
                                      <div className="tick-icon"></div>
                                    </div>
                                    <Row>
                                      <Col sm={8}>
                                        <div className="text-start">
                                          <p className="mb-0 text-muted font-weight-15">
                                            {product.Product_Cat}
                                          </p>
                                          <h2 className="b mb-2 font-size-13">
                                            {product.Product_Name}
                                          </h2>
                                        </div>
                                      </Col>
                                      <Col sm={4}>
                                        <div className="text-end badge  bg-primary font-size-12">
                                          {product.Product_quantity} P
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                  <Row>
                                    <Col lg="6">
                                      <p className="mb-0">
                                        <i className="bx bx-money align-middle text-primary"></i>
                                        RS : {product.Product_Price}
                                      </p>
                                    </Col>
                                    <Col lg="6">
                                      <div className="float-end">
                                        <p className="mb-0 text-muted">
                                          <b>{product.Product_Price}</b>
                                        </p>
                                      </div>
                                    </Col>
                                  </Row>
                                </CardBody>
                                <span className="position-absolute top-0 start-100 translate-middle badge  bg-success">
                                  Sale
                                </span>
                              </Card>
                            </Col>
                          </>
                        ))
                      ) : (
                        <>
                          <div>
                            <img
                              src={NoData}
                              alt="No Data"
                              className="img-fluid mx-auto d-block"
                              width={200}
                              height={250}
                            />
                          </div>
                        </>
                      )}
                    </Row>
                  </div>
                </>
              )}
            </Col>
            <Col lg={4}>
              <Card>
                <CardBody>
                  <div className="table-responsive">
                    <Table className="table align-middle mb-0 table-nowrap">
                      <thead className="table">
                        <tr>
                          <th>Product</th>
                          <th>Price</th>
                          <th>Quantity</th>
                          <th colSpan="2">Total</th>
                        </tr>
                      </thead>
                      {selectedProduct.length > 0 ? (
                        <>
                          <tbody>
                            {selectedProduct.map(product => (
                              <tr key={product.id}>
                                <td>
                                  <img
                                    src={product.Product_Images}
                                    alt="product-img"
                                    title="product-img"
                                    className="avatar-xs"
                                  />
                                </td>

                                <td>RS {product.Product_Price}</td>
                                <td>
                                  <div style={{ width: "120px" }}>
                                    <div className="input-group">
                                      <div className="input-group-append">
                                        <button
                                          type="button"
                                          className="btn btn-primary"
                                          onClick={() => {
                                            countDown(product.id)
                                          }}
                                        >
                                          -
                                        </button>
                                      </div>
                                      <Input
                                        min={1}
                                        type="text"
                                        value={product.quantity}
                                        name="demo_vertical"
                                        readOnly
                                      />
                                      <div className="input-group-prepend">
                                        <button
                                          type="button"
                                          className="btn btn-primary"
                                          onClick={() => {
                                            countUP(product.id)
                                          }}
                                        >
                                          +
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>RS {calculateTotal(product)}</td>

                                <td>
                                  <Link
                                    to="#"
                                    onClick={() => removeCartItem(product.id)}
                                    className="action-icon text-danger"
                                  >
                                    {" "}
                                    <i className="mdi mdi-trash-can font-size-18" />
                                  </Link>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                          <tfoot>
                            <tr>
                              <td colSpan="3">
                                <h6 className="m-0 text-end">Total Qty : </h6>
                              </td>
                              <td>{totalQuantity}</td>
                            </tr>
                            <tr>
                              <td colSpan="3">
                                <h6 className="m-0 text-end">Sub Total : </h6>
                              </td>
                              <td>RS {totalPrice}</td>
                            </tr>
                            <tr>
                              <td colSpan="3">
                                <h6 className="m-0 text-end">Total : </h6>
                              </td>
                              <td>RS {totalPrice}</td>
                            </tr>
                          </tfoot>
                        </>
                      ) : (
                        <tbody>
                          <tr>
                            <td
                              colSpan="5"
                              className="text-center font-size-14"
                            >
                              No Data Available
                            </td>
                          </tr>
                        </tbody>
                      )}
                      {/* <tbody>
                        <tr>
                          <td colSpan="3">
                            <h6 className="m-0 text-end">Total Qty : </h6>
                          </td>
                          <td>{getTotalQuantity()}</td>
                        </tr>
                        <tr>
                          <td colSpan="3">
                            <h6 className="m-0 text-end">Sub Total : </h6>
                          </td>
                          <td>RS {getSubTotal()}</td>
                        </tr>
                        <tr>
                          <td colSpan="3">
                            <h6 className="m-0 text-end">Total : </h6>
                          </td>
                          <td>RS {getTotal()}</td>
                        </tr>
                      </tbody> */}
                    </Table>
                  </div>
                  <div className="d-grid gap-2 mt-3">
                    <Button
                      type="button"
                      className="btn-lg"
                      style={{ backgroundColor: "#082744" }}
                    >
                      Grand Total : RS {totalPrice}
                    </Button>
                  </div>
                  <div
                    className="d-flex flex-wrap gap-3 mt-3"
                    style={{ justifyContent: "center" }}
                  >
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={holdSaleClick}
                      disabled={selectedProduct.length === 0}
                    >
                      <i className="bx bx-hourglass font-size-16 align-middle me-2"></i>{" "}
                      Hold
                    </button>
                    <button
                      type="button"
                      className="btn btn-warning"
                      onClick={Reset}
                    >
                      <i className="bx bx-reset font-size-16 align-middle me-2"></i>{" "}
                      Reset
                    </button>
                    <button
                      type="button"
                      className="btn btn-success "
                      disabled={selectedProduct.length === 0}
                      onClick={MakePayment}
                    >
                      <i className="bx bx-wallet font-size-16 align-middle me-2"></i>{" "}
                      Payment
                    </button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <CustomerCreationModal
        isOpen={OpenCustomerModal}
        toggle={toggleCustomerModal}
        fetchCustomer={fetchCustomer}
        onNewCustomer={handleNewCustomer}
      />
      <MakePaymentModal
        isOpen={makePayment}
        toggle={togglePaymentModal}
        RefNo={unHoldRefNo}
        totalQuantity={totalQuantity}
        totalPrice={totalPrice}
        selectedProduct={AllSaleData}
        ResetHoldValue={setResetHold}
        ResetDirectSaleValue={setDirectSale}
        FetchProducts={setFilteredProducts}
      />
    </React.Fragment>
  )
}

export default ProductList
