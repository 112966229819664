import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import { Link, useNavigate } from "react-router-dom"

import {
  Button,
  Col,
  Container,
  Collapse,
  Input,
  Row,
  Label,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap"
//Date Picker
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"
import classnames from "classnames"

import toastr from "toastr"
import "toastr/build/toastr.min.css"

// import { cryptoOrderData } from "../../../common/data";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import axios from "axios"
import { use } from "i18next"
import Select from "react-select"
import apiURl from "url"
const AllControls = props => {
  //meta title
  document.title = "Setting"
  const navigate = useNavigate()
  //   const role_ = localStorage.getItem("role")
  //   if (role_ !== "admin") {
  //     navigate("/")
  //   }

  const [col1, setcol1] = useState(false)
  const [col2, setcol2] = useState(false)
  const [col3, setcol3] = useState(false)
  const [col4, setcol4] = useState(false)

  const t_col1 = () => {
    setcol1(!col1)
    setcol2(false)
    setcol3(false)
    setcol4(false)
  }
  const t_col2 = () => {
    setcol2(!col2)
    setcol1(false)
    setcol3(false)
    setcol4(false)
  }
  const t_col3 = () => {
    setcol3(!col3)
    setcol1(false)
    setcol2(false)
    setcol4(false)
  }
  const t_col4 = () => {
    setcol4(!col4)
    setcol1(false)
    setcol2(false)
    setcol3(false)
  }

  //Category
  const [Pcategory, setPcategory] = useState("")
  const [catloading, setcatloading] = useState(false)
  const SubmitCategory = async () => {
    if (Pcategory.length == 0) {
      toastr.error("Please Enter Category Name")
    } else {
      setcatloading(true)
      const CatData = new FormData()
      CatData.append("product_category", Pcategory)

      try {
        const url = `${apiURl}/api/addProductCategory`
        const response = await axios.post(url, CatData, {
          headers: {
            "Content-Type": "application/json",
          },
        })

        if (response.data.success === "success") {
          setcol1(!col1)
          toastr.success(response.data.message)
          setcatloading(false)
          setPcategory("")
        } else {
          toastr.error(response.data.message)
        }
      } catch (error) {
        setcatloading(false)
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toastr.error(error.response.data.message)
        } else {
          toastr.error("An unexpected error occurred.")
        }
        console.error("Error:", error)
      } finally {
        setcatloading(false)
      }
    }
  }

  //Unit
  const [Punit, setPunit] = useState("")
  const [unitloading, setUnitloading] = useState(false)
  const SubmitUnit = async () => {
    if (Punit.length == 0) {
      toastr.error("Please Enter Unit")
    } else {
      setUnitloading(true)
      const UnitData = new FormData()
      UnitData.append("product_unit", Punit)

      try {
        const url = `${apiURl}/api/addProductUnit`
        const response = await axios.post(url, UnitData, {
          headers: {
            "Content-Type": "application/json",
          },
        })

        if (response.data.success === "success") {
          setcol2(!col2)
          toastr.success(response.data.message)
          setUnitloading(false)
          setPunit("")
        } else {
          toastr.error(response.data.message)
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toastr.error(error.response.data.message)
        } else {
          toastr.error("An unexpected error occurred.")
        }
        console.error("Error:", error)
      } finally {
        setUnitloading(false)
      }
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xl="10">
              <div>
                <h5 className="mt-2">Controls</h5>
              </div>
            </Col>
          </Row>

          <Row className="pt-3">
            <Col lg="6">
              <div className="accordion" id="accordion">
                <div className="accordion-item mb-3">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className={classnames("accordion-button", "fw-medium", {
                        collapsed: !col1,
                      })}
                      type="button"
                      onClick={t_col1}
                      style={{ cursor: "pointer", backgroundColor: "white" }}
                    >
                      Product Category
                    </button>
                  </h2>

                  <Collapse isOpen={col1} className="accordion-collapse">
                    <div className="accordion-body">
                      <Row>
                        <Col xl={12}>
                          <Row>
                            <Col xl={8}>
                              <div className="mb-3">
                                <label>Product Category</label>
                                <Input
                                  className="form-control"
                                  type="text"
                                  placeholder="category"
                                  name="product_category"
                                  value={Pcategory}
                                  onChange={e => setPcategory(e.target.value)}
                                ></Input>
                              </div>
                            </Col>
                          </Row>
                          <div className="mt-2">
                            <label></label>
                            {catloading ? (
                              <button
                                type="button"
                                className="btn btn-dark "
                                disabled
                              >
                                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                                Loading
                              </button>
                            ) : (
                              <Button
                                className="btn btn-sm"
                                color="primary"
                                onClick={SubmitCategory}
                              >
                                Submit
                              </Button>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Collapse>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <div className="accordion" id="accordion">
                <div className="accordion-item mb-3">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className={classnames("accordion-button", "fw-medium", {
                        collapsed: !col1,
                      })}
                      type="button"
                      onClick={t_col2}
                      style={{ cursor: "pointer", backgroundColor: "white" }}
                    >
                      Product Unit
                    </button>
                  </h2>

                  <Collapse isOpen={col2} className="accordion-collapse">
                    <div className="accordion-body">
                      <Row>
                        <Col xl={12}>
                          <Row>
                            <Col xl={8}>
                              <div className="mb-3">
                                <label>Product Unit</label>
                                <Input
                                  className="form-control"
                                  type="text"
                                  placeholder="unit"
                                  name="product_unit"
                                  value={Punit}
                                  onChange={e => setPunit(e.target.value)}
                                ></Input>
                              </div>
                            </Col>
                          </Row>
                          <div className="mt-2">
                            <label></label>
                            {unitloading ? (
                              <button
                                type="button"
                                className="btn btn-dark btn-sm"
                                disabled
                              >
                                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                                Loading
                              </button>
                            ) : (
                              <Button
                                className="btn btn-sm"
                                color="primary"
                                onClick={SubmitUnit}
                              >
                                Submit
                              </Button>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Collapse>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AllControls
