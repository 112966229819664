import React, { useEffect, useState } from "react"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  FormFeedback,
  Label,
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import apiURl from "url"
import axios from "axios"

import toastr from "toastr"
import "toastr/build/toastr.min.css"

//firabse
const { v4: uuidv4 } = require("uuid")
// import { storage } from "config/firebase"

const UserCreationModal = ({
  isOpen,
  toggle,
  editMode,
  editId,
  modalData,
  fetchUsers,
}) => {
  const initialUserData = {
    user_name: "",
    user_email: "",
    user_city: "",
    user_role: "",
    user_status: "",
    user_password: "",
    user_conf_pass: "",
    user_img_file: null,
    user_img_preview: "",
    user_img: "",
  }
  const [UserData, setUserData] = useState(initialUserData)

  useEffect(() => {
    if (modalData) {
      setUserData(initialUserData)
      validation.resetForm()
    }
  }, [modalData])

  //Validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: UserData,
    validationSchema: Yup.object({
      user_name: Yup.string().required("Name is required"),
      user_email: Yup.string()
        .email("Invalid email format")
        .required("Email is required"),
      user_city: Yup.string().required("City is required"),
      user_role: Yup.string().required("Role is required"),
      user_status: Yup.string().required("Status is required"),
      user_password: Yup.string().required("Password is required"),
      user_conf_pass: Yup.string()
        .oneOf([Yup.ref("user_password"), null], "Passwords must match")
        .required("Confirm the password"),
    }),
    onSubmit: values => {
      console.log(values)
    },
  })

  //Submittion
  const handleButtonClick = async () => {
    try {
      const isValid = await validation.validateForm()
      if (Object.keys(isValid).length === 0) {
        handleSubmit()
      } else {
        toastr.error("Please fill out all required fields correctly.")
      }
    } catch (error) {
      console.error("Error during form validation:", error)
    }
  }
  const handleUserData = e => {
    const { name, type, files, value } = e.target

    if (type === "file") {
      const file = e.target.files[0]
      setUserData(prevData => ({
        ...prevData,
        [name]: file,
      }))
    } else {
      setUserData(prevData => ({
        ...prevData,
        [name]: value,
      }))
    }
  }
  const [loading, setLoading] = useState(false)

  const handleSubmit = async () => {
    // e.preventDefault();
    setLoading(true)
    let uploadedImageUrl = UserData.user_img

    // if (UserData.user_img_file) {
    //   const file = UserData.user_img_file
    //   const fileRef = storage.ref(`users/${uuidv4()}-${file.name}`)
    //   await fileRef.put(file)
    //   uploadedImageUrl = await fileRef.getDownloadURL()
    // }

    const UData = new FormData()
    UData.append("user_name", UserData.user_name)
    UData.append("user_email", UserData.user_email)
    UData.append("user_city", UserData.user_city)
    UData.append("user_role", UserData.user_role)
    UData.append("user_status", UserData.user_status)
    UData.append("user_password", UserData.user_password)
    UData.append("user_conf_pass", UserData.user_conf_pass)
    UData.append("user_img", uploadedImageUrl)

    try {
      const url = editMode
        ? `${apiURl}/api/updateUserData/${editId}`
        : `${apiURl}/api/addUserData`
      const response = await axios.post(url, UData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })

      if (response.data.success === "success") {
        toggle()
        toastr.success(response.data.message)
        setLoading(false)
        fetchUsers()
      } else {
        toastr.error("Failed to add product.")
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toastr.error(error.response.data.message)
      } else {
        toastr.error("An unexpected error occurred.")
      }
      console.error("Error:", error)
    } finally {
      setLoading(false)
    }
  }

  //Edit User
  useEffect(() => {
    const fetchUserDataByID = async id => {
      try {
        const response = await axios.get(`${apiURl}/api/getUsersbyId/${id}`)
        const data = response.data

        setUserData({
          user_name: data.user_name || "",
          user_email: data.user_email || "",
          user_city: data.user_city || "",
          user_role: data.user_role || "",
          user_status: data.user_status || "",
          user_password: data.user_password || "",
          user_conf_pass: data.user_password || "",
          user_img: data.user_img || "",
        })
      } catch (error) {
        console.error("Error fetching user data:", error)
      }
    }

    if (editMode && editId && isOpen) {
      fetchUserDataByID(editId)
    } else {
      // Clear user data when not in edit mode or editId is not set
      setUserData(initialUserData)
      validation.resetForm()
    }
  }, [editId, editMode, isOpen])

  const handleImageUpload = async e => {
    const file = e.target.files[0]
    if (file) {
      setUserData(prevData => ({
        ...prevData,
        user_img_file: file,
        user_img_preview: URL.createObjectURL(file),
      }))
    }
  }

  //Password
  const [showPassword, setShowPassword] = useState(false)
  const [showFPassword, setShowFPassword] = useState(false)
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }
  const togglePasswordVisibility2 = () => {
    setShowFPassword(!showFPassword)
  }

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      size="lg"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div>
        <ModalHeader toggle={toggle}>
          {editMode ? "Edit User" : "Create User"}
        </ModalHeader>
        <Form>
          <ModalBody>
            <Row>
              <Col className="col-6">
                <div className="mb-3">
                  <label>Full Name *</label>
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="Name"
                    name="user_name"
                    value={UserData.user_name}
                    onChange={handleUserData}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.user_name &&
                      validation.errors.user_name
                        ? true
                        : false
                    }
                  />

                  {validation.touched.user_name &&
                    validation.errors.user_name && (
                      <FormFeedback type="invalid">
                        {validation.errors.user_name}
                      </FormFeedback>
                    )}
                </div>
                <div className="mb-3">
                  <label>City</label>
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="city"
                    name="user_city"
                    value={UserData.user_city}
                    onChange={handleUserData}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.user_city &&
                      validation.errors.user_city
                        ? true
                        : false
                    }
                  />

                  {validation.touched.user_city &&
                    validation.errors.user_city && (
                      <FormFeedback type="invalid">
                        {validation.errors.user_city}
                      </FormFeedback>
                    )}
                </div>
                <div className="mb-3">
                  <Label htmlFor="formFile" className="form-label">
                    Image
                  </Label>
                  <Input
                    className="form-control"
                    type="file"
                    id="formFile"
                    name="user_img"
                    onChange={handleImageUpload}
                  />
                  {editMode && (
                    <img
                      src={UserData.user_img_preview || UserData.user_img}
                      className="rounded avatar-xs d-inline-block"
                      alt=""
                    />
                  )}
                </div>

                <div className="mb-3">
                  <label>Password</label>
                  <InputGroup>
                    <Input
                      className="form-control"
                      type={showPassword ? "text" : "password"}
                      placeholder="password"
                      name="user_password"
                      value={UserData.user_password}
                      onChange={handleUserData}
                      onBlur={validation.handleBlur}
                      invalid={
                        validation.touched.user_password &&
                        validation.errors.user_password
                          ? true
                          : false
                      }
                    />

                    <InputGroupText
                      onClick={togglePasswordVisibility}
                      style={{ cursor: "pointer" }}
                    >
                      <FontAwesomeIcon
                        icon={showPassword ? faEyeSlash : faEye}
                      />
                    </InputGroupText>
                    {validation.touched.user_password &&
                      validation.errors.user_password && (
                        <FormFeedback type="invalid">
                          {validation.errors.user_password}
                        </FormFeedback>
                      )}
                  </InputGroup>
                </div>
              </Col>
              <Col className="col-6">
                <div className="mb-3">
                  <label>Email</label>
                  <Input
                    className="form-control"
                    type="email"
                    placeholder="email"
                    name="user_email"
                    value={UserData.user_email}
                    onChange={handleUserData}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.user_email &&
                      validation.errors.user_email
                        ? true
                        : false
                    }
                  />

                  {validation.touched.user_email &&
                    validation.errors.user_email && (
                      <FormFeedback type="invalid">
                        {validation.errors.user_email}
                      </FormFeedback>
                    )}
                </div>

                <div className="mb-3">
                  <Label className="control-label" htmlFor="user_role">
                    Role
                  </Label>
                  <select
                    defaultValue="0"
                    className={`form-select ${
                      validation.touched.user_role &&
                      validation.errors.user_role
                        ? "is-invalid"
                        : ""
                    }`}
                    onChange={handleUserData}
                    name="user_role"
                    value={UserData.user_role}
                    onBlur={validation.handleBlur}
                  >
                    <option value={""}>select role...</option>
                    <option value="admin">Admin</option>
                    <option value="employee">Employee</option>
                    <option value="franchises">Franchises</option>
                    <option value="rider">Rider</option>
                  </select>
                  {validation.touched.user_role &&
                    validation.errors.user_role && (
                      <FormFeedback type="invalid">
                        {validation.errors.user_role}
                      </FormFeedback>
                    )}
                </div>
                <div className="mb-3">
                  <Label className="control-label" htmlFor="user_status">
                    Status
                  </Label>
                  <select
                    defaultValue="0"
                    className={`form-select ${
                      validation.touched.user_status &&
                      validation.errors.user_status
                        ? "is-invalid"
                        : ""
                    }`}
                    onChange={handleUserData}
                    onBlur={validation.handleBlur}
                    name="user_status"
                    value={UserData.user_status}
                  >
                    <option value={""}>select status...</option>
                    <option value="active">Active</option>
                    <option value="inActive">InActive</option>
                  </select>
                  {validation.touched.user_status &&
                    validation.errors.user_status && (
                      <FormFeedback type="invalid">
                        {validation.errors.user_status}
                      </FormFeedback>
                    )}
                </div>
                <div
                  className="mb-3"
                  style={editMode ? { marginTop: "46px" } : {}}
                >
                  <label>Confirm Password</label>
                  <InputGroup>
                    <Input
                      className="form-control"
                      type={showFPassword ? "text" : "password"}
                      placeholder="password"
                      name="user_conf_pass"
                      value={UserData.user_conf_pass}
                      onChange={handleUserData}
                      onBlur={validation.handleBlur}
                      invalid={
                        validation.touched.user_conf_pass &&
                        validation.errors.user_conf_pass
                          ? true
                          : false
                      }
                    />

                    <InputGroupText
                      onClick={togglePasswordVisibility2}
                      style={{ cursor: "pointer" }}
                    >
                      <FontAwesomeIcon
                        icon={showFPassword ? faEyeSlash : faEye}
                      />
                    </InputGroupText>
                    {validation.touched.user_conf_pass &&
                      validation.errors.user_conf_pass && (
                        <FormFeedback type="invalid">
                          {validation.errors.user_conf_pass}
                        </FormFeedback>
                      )}
                  </InputGroup>
                </div>
                {/* {userCode && (
                    <div className="mb-3">
                      <label>Code</label>
                      <Input
                        className="form-control"
                        type="text"
                        placeholder=""
                        value={userCode}
                        disabled
                      ></Input>
                    </div>
                  )} */}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="secondary" onClick={toggle}>
              Close
            </Button>

            {/* {userCode ? (
                <Button type="submit" color="primary" onClick={handleEditUser}>
                  Submit Edit
                </Button>
              ) : (
                <Button type="submit" color="primary" onClick={handleSubmit}>
                  Submit
                </Button>
              )} */}
            {editMode ? (
              loading ? (
                <button type="button" className="btn btn-dark " disabled>
                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                  Loading
                </button>
              ) : (
                <Button color="primary" onClick={handleButtonClick}>
                  Edit User
                </Button>
              )
            ) : loading ? (
              <button type="button" className="btn btn-dark " disabled>
                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                Loading
              </button>
            ) : (
              <Button color="primary" onClick={handleButtonClick}>
                Submit
              </Button>
            )}
          </ModalFooter>
        </Form>
      </div>
    </Modal>
  )
}

export default UserCreationModal
