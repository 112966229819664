import React, { useEffect, useRef, useState } from "react"
import { useReactToPrint } from "react-to-print"

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  Form,
  Input,
  InputGroup,
  Table,
  Label,
  Card,
  CardBody,
  Badge,
} from "reactstrap"
import "toastr/build/toastr.min.css"
import "../../assets/style/style.css"

const SalePaymentInvoice = ({ show, toggle, InvoiceData }) => {
  const [loading, setLoading] = useState(false)
  const printRef = useRef()
  const handlePrint = () => {
    handleDivPrint()
    toggle()
  }
  const handleDivPrint = useReactToPrint({
    content: () => printRef.current,
  })
  return (
    <Modal
      isOpen={show}
      size="sm"
      autoFocus={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div>
        <Form>
          <div ref={printRef} className="printable">
            <ModalHeader toggle={toggle}>Invoice POS</ModalHeader>
            <ModalBody>
              <div className="thermal-print">
                <div className="text-center info text-center mb-3">
                  <h6>Milk Shop PVT</h6>
                  <p className="mb-0">Phone Number: +1 5656665656</p>
                  <p className="mb-0">
                    Email:{" "}
                    <a href="mailto:example@gmail.com">example@gmail.com</a>
                  </p>
                </div>
                <div className="text-with-dots">
                  {""} Tax Invoice {""}
                </div>
                <div
                  className="row mb-3"
                  style={{
                    borderBottom: "1px dashed black",
                  }}
                >
                  <div className="col-sm-12 col-md-6">
                    <div className="invoice-user-name">
                      <span>
                        <b>Date: </b>
                      </span>
                      <span>{InvoiceData[0]?.currentDate || ""}</span>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <div className="invoice-user-name">
                      <span>Id: </span>
                      <span>
                        #{InvoiceData[0]?.selectedCustomer || "walk-in"}
                      </span>
                    </div>
                  </div>
                </div>

                {InvoiceData.map(products => (
                  <div key={products.id}>
                    <div>
                      <div className="p-0">
                        {products.Product_Name}{" "}
                        <span>({products.Product_Cat})</span>
                      </div>
                      <div className="product-border">
                        <div className="border-0 d-flex justify-content-between">
                          <span className="">
                            {products.quantity} X {products.Product_Price}
                          </span>
                          <span className="text-end">
                            PKR:{" "}
                            {(
                              products.Product_Price * products.quantity
                            ).toFixed(2)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

                <div className="invoice-total">
                  <span>Total Amount: </span>
                  <span>PKR: {InvoiceData[0]?.totalPrice || "0"}</span>
                </div>
                <div className="invoice-total">
                  <span>Grand Total: </span>
                  <span>PKR: {InvoiceData[0]?.totalPrice || "0"}</span>
                </div>
                <div className="invoice-footer">
                  <p>Code: ABC123</p>
                  <p>Address: Islamabad Pakistan</p>
                </div>
              </div>
            </ModalBody>
          </div>
          <ModalFooter>
            {/* {userCode ? (
                <Button type="submit" color="primary" onClick={handleEditUser}>
                  Submit Edit
                </Button>
              ) : (
                <Button type="submit" color="primary" onClick={handleSubmit}>
                  Submit
                </Button>
              )} */}
            {loading ? (
              <button type="button" className="btn btn-dark " disabled>
                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                Loading
              </button>
            ) : (
              <Button color="primary" onClick={handlePrint}>
                Print
              </Button>
            )}
            <Button type="button" color="secondary" onClick={toggle}>
              Close
            </Button>
          </ModalFooter>
        </Form>
      </div>
    </Modal>
  )
}

export default SalePaymentInvoice
