import React, { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"

const { v4: uuidv4 } = require("uuid")

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import apiURl from "url"

import toastr from "toastr"
import "toastr/build/toastr.min.css"
import Dropzone from "react-dropzone"
import { formatBytes } from "pages/Functions/functions"
// import { storage } from "config/firebase"

const ViewProduct = () => {
  //meta title
  document.title = "Add Product"
  const { viewId } = useParams()
  const navigator = useNavigate()
  //Edit Mode
  const [editMode, setEditMode] = useState(true)
  const editFields = () => {
    setEditMode(prevState => {
      return !prevState
    })
  }
  //Fetch Product By Id
  const fetchProducts = async viewId => {
    try {
      const response = await axios.get(
        `${apiURl}/api/getProductsById/${viewId}`
      )
      const FProductdata = response.data

      setProductData({
        Product_Name: FProductdata.Product_Name || "",
        Product_Cat: FProductdata.Product_Cat || "",
        Product_Price: FProductdata.Product_Price || "",
        Product_Des: FProductdata.Product_Des || "",
        Product_file: FProductdata.Product_Images,
      })
    } catch (error) {
      console.error("Error fetching products:", error)
    }
  }
  useEffect(() => {
    fetchProducts(viewId)
  }, [viewId])
  //field Data
  const [ProductData, setProductData] = useState({
    Product_Name: "",
    Product_Cat: "",
    Product_Price: "",
    Product_Des: "",
    Product_file: [],
  })
  const handleProductData = e => {
    const { name, value } = e.target
    setProductData(prevData => ({
      ...prevData,
      [name]: value,
    }))
  }
  //handle images
  const handleAcceptedFiles = files => {
    if (files.length > 0) {
      const file = files[0]
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
      setProductData({
        ...ProductData,
        Product_file: file,
      })
    }
  }
  //Final submission
  const [loading, setLoading] = useState(false)
  const handleProductAdd = async e => {
    e.preventDefault()
    setLoading(true)
    try {
      // Upload images to Firebase Storage
      let uploadedImageUrl
      if (ProductData.Product_file && ProductData.Product_file.preview) {
        // Upload new image to Firebase Storage
        const file = ProductData.Product_file
        // const fileRef = storage.ref(`products/${uuidv4()}-${file.name}`)
        // const snapshot = await fileRef.put(file)
        // uploadedImageUrl = await snapshot.ref.getDownloadURL()
      } else {
        // Use existing image URL
        uploadedImageUrl = ProductData.Product_file
      }

      // Prepare FormData with product data and uploaded image URLs
      const PrdData = new FormData()
      PrdData.append("Product_Name", ProductData.Product_Name)
      PrdData.append("Product_Cat", ProductData.Product_Cat)
      PrdData.append("Product_Price", parseFloat(ProductData.Product_Price))
      PrdData.append("Product_Des", ProductData.Product_Des)
      PrdData.append("Product_Images", uploadedImageUrl)

      // Send POST request to API endpoint
      const response = await axios.post(
        `${apiURl}/api/updateProductData/${viewId}`,
        PrdData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )

      // Handle response
      console.log("Response received:", response.data)

      if (response.data.success === "success") {
        toastr.success(response.data.message)
        navigator("/all-stock-products")
        setLoading(false)
      } else {
        toastr.error("Failed to add product.")
        setLoading(false)
      }
    } catch (error) {
      console.error("Error:", error)
      toastr.error("Failed to add product.")
      setLoading(false)
    }
  }

  //close
  const closetab = () => {
    navigator("/all-stock-products")
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Ecommerce" breadcrumbItem="Add Product" />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col className="md-6">
                      <CardTitle>Basic Information</CardTitle>
                      <p className="card-title-desc mb-4">
                        Fill all information below
                      </p>
                    </Col>
                    <Col className="md-6">
                      <div className="float-end">
                        <input
                          className={
                            editMode ? "btn btn-danger" : "btn btn-primary"
                          }
                          type="button"
                          value={editMode ? "Edit" : "In Edit-Mode"}
                          onClick={editFields}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm="6">
                      <div className="mb-3">
                        <Label htmlFor="productname">Product Name</Label>
                        <Input
                          id="productname"
                          name="Product_Name"
                          type="text"
                          className="form-control"
                          placeholder="Product Name"
                          value={ProductData.Product_Name}
                          onChange={handleProductData}
                          disabled={editMode}
                        />
                      </div>

                      <div className="mb-3">
                        <Label htmlFor="price">Price</Label>
                        <Input
                          id="price"
                          name="Product_Price"
                          type="text"
                          className="form-control"
                          placeholder="Price"
                          value={ProductData.Product_Price}
                          onChange={handleProductData}
                          disabled={editMode}
                        />
                      </div>
                    </Col>

                    <Col sm="6">
                      <div className="mb-3">
                        <Label className="control-label">Category</Label>
                        <select
                          className="form-control select2"
                          name="Product_Cat"
                          value={ProductData.Product_Cat}
                          onChange={handleProductData}
                          disabled={editMode}
                        >
                          <option>Select</option>
                          <option value="FA">Fashion</option>
                          <option value="EL">Electronic</option>
                        </select>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="6">
                      <div className="mb-3">
                        <Label htmlFor="productdesc">Product Description</Label>
                        <textarea
                          className="form-control mb-3"
                          id="productdesc"
                          name="Product_Des"
                          rows="5"
                          placeholder="Product Description"
                          value={ProductData.Product_Des}
                          onChange={handleProductData}
                          disabled={editMode}
                        />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <CardTitle className="mb-3">Product Images</CardTitle>
                  <Form>
                    <Row>
                      <Col className="md-6">
                        <Dropzone
                          onDrop={acceptedFiles => {
                            handleAcceptedFiles(acceptedFiles)
                          }}
                          maxFiles={1}
                          accept="image/*"
                          disabled={editMode}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="dz-message needsclick">
                                  <div className="mb-3">
                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                  </div>
                                  <h4>
                                    {editMode
                                      ? "Enable edit mode for uplaod."
                                      : "Drop files here or click to upload."}
                                  </h4>
                                </div>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                      </Col>
                      <Col className="md-6">
                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          {ProductData.Product_file && (
                            <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                              <div className="p-2">
                                <Row className="align-items-center">
                                  <Col className="col-auto">
                                    {ProductData.Product_file.preview ? (
                                      <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={ProductData.Product_file.name}
                                        src={ProductData.Product_file.preview}
                                      />
                                    ) : (
                                      <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt="Product"
                                        src={ProductData.Product_file} // This will be the URL from the fetched data
                                      />
                                    )}
                                  </Col>
                                  <Col>
                                    <Link
                                      to="#"
                                      className="text-muted font-weight-bold"
                                    >
                                      {ProductData.Product_file.name ||
                                        "Existing Image"}
                                    </Link>
                                    {ProductData.Product_file.formattedSize && (
                                      <p className="mb-0">
                                        <strong>
                                          {
                                            ProductData.Product_file
                                              .formattedSize
                                          }
                                        </strong>
                                      </p>
                                    )}
                                  </Col>
                                </Row>
                              </div>
                            </Card>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
              {editMode ? (
                <Row className="mb-3">
                  <Col className="md-6">
                    <Button
                      onClick={closetab}
                      color="danger"
                      className="btn btn-danger "
                    >
                      Close
                    </Button>
                  </Col>
                </Row>
              ) : (
                <Row className="mb-3">
                  <Col className="md-6">
                    <Button
                      onClick={closetab}
                      color="danger"
                      className="btn btn-danger "
                    >
                      Close
                    </Button>
                  </Col>
                  <Col className="md-6">
                    <div className="float-end d-flex flex-wrap gap-2">
                      {loading ? (
                        <button
                          type="button"
                          className="btn btn-dark "
                          disabled
                        >
                          <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                          Loading
                        </button>
                      ) : (
                        <Button
                          onClick={handleProductAdd}
                          color="primary"
                          className="btn btn-primary "
                        >
                          Save Changes
                        </Button>
                      )}
                    </div>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ViewProduct
