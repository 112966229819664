import PropTypes from "prop-types"
import React, { useState } from "react"
import { Modal, ModalBody, Input } from "reactstrap"
import WarningIcon from "../../assets/icons/warning.png"



const HoldModal = ({ show, onHoldClick, onCloseClick, HoldRefNo }) => {
  const handleRefNo = e => {
    HoldRefNo(e.target.value)
  }
  return (
    <Modal size="sm" isOpen={show} toggle={onCloseClick} centered={true}>
      <div className="modal-content">
        <ModalBody className="px-4 py-5 text-center">
          <button
            type="button"
            onClick={onCloseClick}
            className="btn-close position-absolute end-0 top-0 m-3"
          ></button>
          <div className="avatar-sm mb-4 mx-auto">
            {/* <div className="avatar-title bg-primary text-primary bg-opacity-10 font-size-20 rounded-3">
              <i className="mdi mdi-warning-can-outline"></i>
            </div> */}
            <img src={WarningIcon} width={50} />
          </div>
          <p className="text-muted font-size-16 mb-4">
            Hold Invoice ? Same Reference will replace the old list if exist!!
          </p>

          <div className="mb-3">
            <Input
              className="form-control"
              type="number"
              min={1}
              placeholder="Enter Reference Number !"
              name="customer_ph_no"
              onChange={handleRefNo}
              onWheel={e => e.target.blur()}
            />
          </div>

          <div className="hstack gap-2 justify-content-center mb-0">
            <button
              type="button"
              className="btn btn-danger"
              onClick={onHoldClick}
            >
              Yes, OK
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onCloseClick}
            >
              No, Cancel
            </button>
          </div>
        </ModalBody>
      </div>
    </Modal>
  )
}

HoldModal.propTypes = {
  onCloseClick: PropTypes.func,
  onHoldClick: PropTypes.func,
  show: PropTypes.any,
  HoldRefNo: PropTypes.any,
}

export default HoldModal
