import React, { useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom"

import {
  Button,
  Card,
  CardBody,
  UncontrolledTooltip,
  Col,
  Container,
  Badge,
  Row,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import apiURl from "url"

import toastr from "toastr"
import "toastr/build/toastr.min.css"
import TableContainer from "components/Common/TableContainer"
import DeleteModal from "components/Common/DeleteModal"
import Loader from "hooks/loader"

import { LazyLoadImage } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"

import useFetchExpense from "hooks/useFetchExpsens"
import formatAmountCurrency from "components/Functions/main"

const AllSales = () => {
  //meta title
  document.title = "All Sales"
  const { expense, loading, fetchexpense } = useFetchExpense()
  const [deleteModal, setDeleteModal] = useState(false)

  const columns = useMemo(
    () => [
      {
        Header: "Code",
        accessor: "expense_code",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <>
              <Badge className="badge-soft-danger font-size-12">
                {cellProps.value}
              </Badge>
            </>
          )
        },
      },
      {
        Header: "Title",
        accessor: "expense_title",
        disableFilters: true,
        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: "Warehouse",
        accessor: "expense_warehouse",
        disableFilters: true,
        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: "Category",
        accessor: "expense_category",
        disableFilters: true,
        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: "Amount",
        accessor: "expense_amount",
        disableFilters: true,
        Cell: cellProps => {
          const amount = parseFloat(cellProps.value)
          return formatAmountCurrency(amount, "PKR")
        },
      },
      {
        Header: "Expense Date",
        accessor: "expense_date",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <>
              <Badge className="badge-soft-primary me-1 font-size-12">
                {cellProps.value}
              </Badge>
            </>
          )
        },
      },

      {
        Header: "Action",
        accessor: "id",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <>
              <div className="d-flex gap-3">
                <Link
                  to={`/view-expense/${cellProps.value}`}
                  className="text-primary"
                >
                  <i className="mdi mdi-eye font-size-18" id="viewtooltip" />
                  <UncontrolledTooltip placement="top" target="viewtooltip">
                    View
                  </UncontrolledTooltip>
                </Link>
                <Link
                  to="#"
                  className="text-danger"
                  onClick={() => showDeleteModal(cellProps.value)}
                >
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                  />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </div>
            </>
          )
        },
      },
    ],
    []
  )

  //Delete User
  const [delid, setDelid] = useState([])
  const showDeleteModal = id => {
    setDelid(id)
    setDeleteModal(true)
  }
  const handleexpenseDelete = async () => {
    try {
      const response = await axios.delete(
        `${apiURl}/api/deleteexpense/${delid}`
      )

      if (response.data.success === "success") {
        toastr.success(response.data.message)
        setDeleteModal(false)
        fetchexpense() // Refresh the expense list
      } else {
        toastr.error("Failed to delete expense.")
      }
    } catch (error) {
      console.error("Error:", error)
    }
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleexpenseDelete}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcr umb */}
          <Breadcrumbs title="Ecommerce" breadcrumbItem="All Sales" />

          <Row className="pt-3">
            <Col lg="12">
              {loading ? (
                <Loader />
              ) : (
                <Card>
                  <CardBody>
                    <TableContainer
                      columns={columns}
                      data={expense}
                      isGlobalFilter={true}
                      isAddOptions={false}
                      customPageSize={10}
                    />
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AllSales
