import React, { useEffect, useState } from "react"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  FormFeedback,
  Label,
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import apiURl from "url"
import axios from "axios"

import toastr from "toastr"
import "toastr/build/toastr.min.css"
import Select from "react-select"

//firabse
const { v4: uuidv4 } = require("uuid")
// import { storage } from "config/firebase"

const BranchModal = ({
  isOpen,
  toggle,
  editMode,
  editId,
  modalData,
  fetchBranch,
  wareHouse,
}) => {
  const initialBranchData = {
    Branch_name: "",
    ph_no: "",
    Branch_city: "",
    Branch_status: "",
    Branch_warehouse: "",
    w_code: "",
    Branch_address: "",
  }

  //Insert New User
  const [BranchData, setBranchData] = useState(initialBranchData)
  useEffect(() => {
    if (modalData) {
      setBranchData(initialBranchData)
      validation.resetForm()
    }
  }, [modalData])

  const handleBranchData = e => {
    const { name, value } = e.target
    setBranchData(prevData => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleBranchWarehouse = selectedOption => {
    setBranchData(prevData => ({
      ...prevData,
      Branch_warehouse: selectedOption ? selectedOption.value : "",
    }))
    validation.setFieldValue(
      "Branch_warehouse",
      selectedOption ? selectedOption.value : ""
    )
    validation.setFieldTouched("Branch_warehouse", true)
  }

  //Submittion
  const [loading, setLoading] = useState(false)
  const handleSubmit = async () => {
    setLoading(true)
    const BData = new FormData()
    BData.append("Branch_name", BranchData.Branch_name)
    BData.append("Branch_phone", BranchData.ph_no)
    BData.append("Branch_city", BranchData.Branch_city)
    BData.append("Branch_status", BranchData.Branch_status)
    BData.append("Branch_address", BranchData.Branch_address)
    BData.append("Branch_warehouse", BranchData.Branch_warehouse)

    try {
      const url = editMode
        ? `${apiURl}/api/updateBranchData/${editId}`
        : `${apiURl}/api/addBranchData`
      const response = await axios.post(url, BData, {
        headers: {
          "Content-Type": "application/json",
        },
      })

      if (response.data.success === "success") {
        toggle()
        setLoading(false)
        fetchBranch()
        toastr.success(response.data.message)
      } else {
        toastr.error("Failed to add Branch.")
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toastr.error(error.response.data.message)
      } else {
        toastr.error("An unexpected error occurred.")
      }
      console.error("Error:", error)
    } finally {
      setLoading(false)
    }
  }

  //Validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: BranchData,
    validationSchema: Yup.object({
      Branch_name: Yup.string().required("Name is required"),
      ph_no: Yup.string().required("ph no is required"),
      Branch_city: Yup.string().required("City is required"),
      Branch_status: Yup.string().required("Status is required"),
      Branch_warehouse: Yup.string().required("Warehouse is required"),
      Branch_address: Yup.string().required("Address is required"),
    }),
    onSubmit: values => {
      console.log(values)
    },
  })
  const handleButtonClick = async () => {
    try {
      const isValid = await validation.validateForm()
      if (Object.keys(isValid).length === 0) {
        handleSubmit()
      } else {
        toastr.error("Please fill out all required fields correctly.")
      }
    } catch (error) {
      console.error("Error during form validation:", error)
    }
  }

  //Edit User
  useEffect(() => {
    const fetchBranchDataByID = async id => {
      try {
        const response = await axios.get(`${apiURl}/api/getBranchDataId/${id}`)
        const BranchData = response.data

        setBranchData({
          Branch_name: BranchData.Branch_name || "",
          ph_no: BranchData.Branch_phone || "",
          w_code: BranchData.Branch_code || "",
          Branch_city: BranchData.Branch_city || "",
          Branch_status: BranchData.Branch_status || "",
          Branch_warehouse: BranchData.Branch_warehouse || "",
          Branch_address: BranchData.Branch_address || "",
        })
      } catch (error) {
        console.error("Error fetching users:", error)
      }
    }
    if (editId && editMode && isOpen) {
      fetchBranchDataByID(editId)
    } else {
      validation.resetForm()
      setBranchData(initialBranchData)
      validation.resetForm()
    }
  }, [editId, editMode, isOpen])

  //css
  const customStyles = {
    menu: provided => ({
      ...provided,
      zIndex: 9999, // Ensure the dropdown appears above other elements
      backgroundColor: "white", // Set the background color for the dropdown menu
    }),
  }

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      size="lg"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div>
        <Form>
          <ModalHeader toggle={toggle}>
            {editMode ? "Edit Branch" : "Create Branch"}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col className="col-6">
                <div className="mb-3">
                  <label>Branch Name*</label>
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="Name"
                    name="Branch_name"
                    value={BranchData.Branch_name}
                    onChange={handleBranchData}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.Branch_name &&
                      validation.errors.Branch_name
                        ? true
                        : false
                    }
                  />

                  {validation.touched.Branch_name &&
                    validation.errors.Branch_name && (
                      <FormFeedback type="invalid">
                        {validation.errors.Branch_name}
                      </FormFeedback>
                    )}
                </div>
                <div className="mb-3">
                  <label>Ph No*</label>
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="ph no"
                    name="ph_no"
                    value={BranchData.ph_no}
                    onChange={handleBranchData}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.ph_no && validation.errors.ph_no
                        ? true
                        : false
                    }
                  />

                  {validation.touched.ph_no && validation.errors.ph_no && (
                    <FormFeedback type="invalid">
                      {validation.errors.ph_no}
                    </FormFeedback>
                  )}
                </div>
                <div className="mb-3">
                  <label>City*</label>
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="city"
                    name="Branch_city"
                    value={BranchData.Branch_city}
                    onChange={handleBranchData}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.Branch_city &&
                      validation.errors.Branch_city
                        ? true
                        : false
                    }
                  />

                  {validation.touched.Branch_city &&
                    validation.errors.Branch_city && (
                      <FormFeedback type="invalid">
                        {validation.errors.Branch_city}
                      </FormFeedback>
                    )}
                </div>
              </Col>
              <Col className="col-6">
                <div className="mb-3">
                  <Label className="control-label" htmlFor="Branch_status">
                    Status*
                  </Label>
                  <select
                    defaultValue="0"
                    className={`form-select ${
                      validation.touched.Branch_status &&
                      validation.errors.Branch_status
                        ? "is-invalid"
                        : ""
                    }`}
                    onChange={handleBranchData}
                    onBlur={validation.handleBlur}
                    name="Branch_status"
                    value={BranchData.Branch_status}
                  >
                    <option value={""}>select status...</option>
                    <option value="active">Active</option>
                    <option value="inActive">InActive</option>
                  </select>
                  {validation.touched.Branch_status &&
                    validation.errors.Branch_status && (
                      <FormFeedback type="invalid">
                        {validation.errors.Branch_status}
                      </FormFeedback>
                    )}
                </div>
                <div className="mb-3">
                  <label>Address*</label>
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="Address"
                    name="Branch_address"
                    value={BranchData.Branch_address}
                    onChange={handleBranchData}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.Branch_address &&
                      validation.errors.Branch_address
                        ? true
                        : false
                    }
                  />

                  {validation.touched.Branch_address &&
                    validation.errors.Branch_address && (
                      <FormFeedback type="invalid">
                        {validation.errors.Branch_address}
                      </FormFeedback>
                    )}
                </div>
                <div className="mb-3">
                  <Label className="control-label" htmlFor="Branch_warehouse">
                    WareHouse*
                  </Label>
                  <Select
                    defaultValue="0"
                    className={`select2-selection ${
                      validation.touched.Branch_warehouse &&
                      validation.errors.Branch_warehouse
                        ? "is-invalid"
                        : ""
                    }`}
                    onChange={handleBranchWarehouse}
                    onBlur={() =>
                      validation.setFieldTouched("Branch_warehouse", true)
                    }
                    value={
                      wareHouse?.find(
                        option => option.value === BranchData.Branch_warehouse
                      ) || { value: "", label: "Select..." }
                    }
                    options={[
                      ...(wareHouse.length > 0
                        ? wareHouse
                        : [{ value: "", label: "No data Found" }]),
                    ]}
                    styles={customStyles}
                  />
                  {validation.touched.Branch_warehouse &&
                    validation.errors.Branch_warehouse && (
                      <FormFeedback type="invalid">
                        {validation.errors.Branch_warehouse}
                      </FormFeedback>
                    )}
                </div>
                <div className="mb-3">
                  <label>Branch Code</label>
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="code"
                    name="w_code"
                    value={BranchData.w_code}
                    onChange={handleBranchData}
                    disabled
                  />
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="secondary" onClick={toggle}>
              Close
            </Button>

            {/* {userCode ? (
            <Button type="submit" color="primary" onClick={handleEditUser}>
              Submit Edit
            </Button>
          ) : (
            <Button type="submit" color="primary" onClick={handleSubmit}>
              Submit
            </Button>
          )} */}
            {editMode ? (
              loading ? (
                <button type="button" className="btn btn-dark " disabled>
                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                  Loading
                </button>
              ) : (
                <Button color="primary" onClick={handleButtonClick}>
                  Edit House
                </Button>
              )
            ) : loading ? (
              <button type="button" className="btn btn-dark " disabled>
                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                Loading
              </button>
            ) : (
              <Button color="primary" onClick={handleButtonClick}>
                Submit
              </Button>
            )}
          </ModalFooter>
        </Form>
      </div>
    </Modal>
  )
}

export default BranchModal
