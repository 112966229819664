const formatAmountCurrency = (amount, currency) => {
  if (typeof amount !== "number" || isNaN(amount)) {
    console.error("Invalid amount:", amount)
    return amount
  }

  return amount.toLocaleString("en-US", {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 2,
  })
}

export default formatAmountCurrency
