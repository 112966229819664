import React, { useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom"

import {
  Button,
  Card,
  CardBody,
  UncontrolledTooltip,
  Col,
  Container,
  Row,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import apiURl from "url"

import toastr from "toastr"
import "toastr/build/toastr.min.css"
import TableContainer from "components/Common/TableContainer"
import DeleteModal from "components/Common/DeleteModal"

import Loader from "hooks/loader"

import { LazyLoadImage } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"

import useFetchexpenseCat from "hooks/useFetchExpenseCat"
import ExpenseCategoryModal from "components/ExpenseCat/expenseCat"

const AllExpensesCategories = () => {
  //meta title
  document.title = "All Expense Categories"
  const { expenseCat, loading, fetchexpenseCat } = useFetchexpenseCat()
  const [deleteModal, setDeleteModal] = useState(false)

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "category_name",
        disableFilters: true,
        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },

      {
        Header: "Date",
        accessor: "created_date",
        disableFilters: true,
        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },

      {
        Header: "Action",
        accessor: "id",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <>
              <div className="d-flex gap-3">
                <Link
                  to="#"
                  onClick={() => ViewEditModal(cellProps.value)}
                  className="text-primary"
                >
                  <i className="mdi mdi-eye font-size-18" id="viewtooltip" />
                  <UncontrolledTooltip placement="top" target="viewtooltip">
                    View
                  </UncontrolledTooltip>
                </Link>
                <Link
                  to="#"
                  className="text-danger"
                  onClick={() => showDeleteModal(cellProps.value)}
                >
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                  />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </div>
            </>
          )
        },
      },
    ],
    []
  )

  //Delete User
  const [delid, setDelid] = useState([])
  const showDeleteModal = id => {
    setDelid(id)
    setDeleteModal(true)
  }
  const handleexpenseCatDelete = async () => {
    try {
      const response = await axios.delete(
        `${apiURl}/api/deleteexpenseCat/${delid}`
      )

      if (response.data.success === "success") {
        toastr.success(response.data.message)
        setDeleteModal(false)
        fetchexpenseCat() // Refresh the expense list
      } else {
        toastr.error("Failed to delete expense.")
      }
    } catch (error) {
      console.error("Error:", error)
    }
  }

  //Add Expense Category
  const [editId, setEditId] = useState("")
  const [editMode, setEditMode] = useState(false)
  const [ECatModal, setECatModal] = useState(false)
  const [EcatData, setEcatData] = useState(false)
  const toggleExpcatModal = () => {
    setECatModal(!ECatModal)
    setEcatData(false)
  }
  const AddExpCategory = () => {
    setECatModal(true)
    setEcatData(true)
    setEditId("")
    setEditMode(false)
  }

  //view Cat

  const ViewEditModal = id => {
    setECatModal(true)
    setEcatData(false)
    setEditMode(true)
    setEditId(id)
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleexpenseCatDelete}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcr umb */}
          <Breadcrumbs
            title="Ecommerce"
            breadcrumbItem="All Expense Categories"
          />

          <Row className="pt-3">
            <Col lg="12">
              {loading ? (
                <Loader />
              ) : (
                <Card>
                  <CardBody>
                    <Row>
                      <Col>
                        <div className="float-end">
                          <Button
                            type="button"
                            color="primary"
                            className="w-md btn btn-sm"
                            onClick={AddExpCategory}
                          >
                            <i className="fa fa-fw fa-plus" />
                            Create Category
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    <TableContainer
                      columns={columns}
                      data={expenseCat}
                      isGlobalFilter={true}
                      isAddOptions={false}
                      customPageSize={10}
                    />
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
        </Container>
      </div>
      <ExpenseCategoryModal
        isOpen1={ECatModal}
        toggle1={toggleExpcatModal}
        editId={editId}
        EcatData={EcatData}
        editMode={editMode}
        fetchExpenseCat={fetchexpenseCat}
      />
    </React.Fragment>
  )
}

export default AllExpensesCategories
