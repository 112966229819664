import { useState, useEffect } from "react"
import axios from "axios"
import apiURl from "url"

const useFetchWarehouses = () => {
  const [FHouse, setFHouse] = useState([])
  const [FullHouseData, setFullHouseData] = useState([])
  const [loading, setLoading] = useState(true)
  const fetchHouse = async () => {
    try {
      setLoading(true)
      const response = await axios.get(`${apiURl}/api/getWarehouseData`)
      const activeWarehouse = response.data.filter(
        warehouse => warehouse.warehouse_status === "active"
      )
      const houseName = activeWarehouse.map(name => ({
        value: name.warehouse_code,
        label: `${name.warehouse_name} (${name.warehouse_code})`,
      }))
      setFHouse(houseName)
      setFullHouseData(response.data)
    } catch (error) {
      console.error("Error fetching branches:", error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchHouse()
  }, [])
  return { FHouse, FullHouseData, loading, fetchHouse }
}

export default useFetchWarehouses
