import React, { useEffect, useState } from "react"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  FormFeedback,
  Label,
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import apiURl from "url"
import axios from "axios"

import toastr from "toastr"
import "toastr/build/toastr.min.css"

//firabse
const { v4: uuidv4 } = require("uuid")
// import { storage } from "config/firebase"
;``
const CustomerCreationModal = ({
  isOpen,
  toggle,
  editMode,
  editId,
  modalData,
  fetchCustomer,
  onNewCustomer,
}) => {
  const initialCustomerData = {
    customer_name: "",
    customer_email: "",
    customer_city: "",
    customer_address: "",
    customer_ph_no: "",
  }
  const [CustomerData, setCustomerData] = useState(initialCustomerData)

  useEffect(() => {
    if (modalData) {
      setCustomerData(initialCustomerData)
      validation.resetForm()
    }
  }, [modalData])

  //Validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: CustomerData,
    validationSchema: Yup.object({
      customer_name: Yup.string().required("Name is required"),
      customer_city: Yup.string().required("City is required"),
      customer_address: Yup.string().required("Address is required"),
    }),
    onSubmit: values => {
      console.log(values)
    },
  })

  //Submittion
  const handleButtonClick = async () => {
    try {
      const isValid = await validation.validateForm()
      if (Object.keys(isValid).length === 0) {
        handleSubmit()
      } else {
        toastr.error("Please fill out all required fields correctly.")
      }
    } catch (error) {
      console.error("Error during form validation:", error)
    }
  }
  const handleCustomerData = e => {
    const { name, value } = e.target

    setCustomerData(prevData => ({
      ...prevData,
      [name]: value,
    }))
  }
  const [loading, setLoading] = useState(false)

  const handleSubmit = async () => {
    // e.preventDefault();
    setLoading(true)

    const UData = new FormData()
    UData.append("customer_name", CustomerData.customer_name)
    UData.append("customer_email", CustomerData.customer_email)
    UData.append("customer_city", CustomerData.customer_city)
    UData.append("customer_address", CustomerData.customer_address)
    UData.append("customer_ph_no", CustomerData.customer_ph_no)

    try {
      const url = editMode
        ? `${apiURl}/api/updateCustomerData/${editId}`
        : `${apiURl}/api/addCustomerData`
      const response = await axios.post(url, UData, {
        headers: {
          "Content-Type": "application/json",
        },
      })

      if (response.data.success === "success") {
        toggle()
        const Custid = response.data.CustomerId
        onNewCustomer({
          value: Custid,
          label: CustomerData.customer_name,
        })
        toastr.success(response.data.message)
        setLoading(false)
        fetchCustomer()
      } else {
        toastr.error("Failed to add product.")
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toastr.error(error.response.data.message)
      } else {
        toastr.error("An unexpected error occurred.")
      }
      console.error("Error:", error)
    } finally {
      setLoading(false)
    }
  }

  //Edit User
  useEffect(() => {
    const fetchCustomerDataByID = async id => {
      try {
        const response = await axios.get(`${apiURl}/api/getUsersbyId/${id}`)
        const data = response.data

        setCustomerData({
          customer_name: data.customer_name || "",
          customer_email: data.customer_email || "",
          customer_city: data.customer_city || "",
          user_role: data.user_role || "",
          user_status: data.user_status || "",
          user_password: data.user_password || "",
          user_conf_pass: data.user_password || "",
          user_img: data.user_img || "",
        })
      } catch (error) {
        console.error("Error fetching user data:", error)
      }
    }

    if (editMode && editId && isOpen) {
      fetchCustomerDataByID(editId)
    } else {
      // Clear user data when not in edit mode or editId is not set
      setCustomerData(initialCustomerData)
      validation.resetForm()
    }
  }, [editId, editMode, isOpen])

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      size="lg"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div>
        <ModalHeader toggle={toggle}>
          {editMode ? "Edit Customer" : "Create Customer"}
        </ModalHeader>
        <Form>
          <ModalBody>
            <Row>
              <Col className="col-6">
                <div className="mb-3">
                  <label>Full Name *</label>
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="Name"
                    name="customer_name"
                    value={CustomerData.customer_name}
                    onChange={handleCustomerData}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.customer_name &&
                      validation.errors.customer_name
                        ? true
                        : false
                    }
                  />

                  {validation.touched.customer_name &&
                    validation.errors.customer_name && (
                      <FormFeedback type="invalid">
                        {validation.errors.customer_name}
                      </FormFeedback>
                    )}
                </div>
                <div className="mb-3">
                  <label>City</label>
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="city"
                    name="customer_city"
                    value={CustomerData.customer_city}
                    onChange={handleCustomerData}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.customer_city &&
                      validation.errors.customer_city
                        ? true
                        : false
                    }
                  />

                  {validation.touched.customer_city &&
                    validation.errors.customer_city && (
                      <FormFeedback type="invalid">
                        {validation.errors.customer_city}
                      </FormFeedback>
                    )}
                </div>
                <div className="mb-3">
                  <label>Address</label>
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="address"
                    name="customer_address"
                    value={CustomerData.customer_address}
                    onChange={handleCustomerData}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.customer_address &&
                      validation.errors.customer_address
                        ? true
                        : false
                    }
                  />

                  {validation.touched.customer_address &&
                    validation.errors.customer_address && (
                      <FormFeedback type="invalid">
                        {validation.errors.customer_address}
                      </FormFeedback>
                    )}
                </div>
              </Col>
              <Col className="col-6">
                <div className="mb-3">
                  <label>Email</label>
                  <Input
                    className="form-control"
                    type="email"
                    placeholder="email"
                    name="customer_email"
                    value={CustomerData.customer_email}
                    onChange={handleCustomerData}
                  />
                </div>
                <div className="mb-3">
                  <label>Phone</label>
                  <Input
                    className="form-control"
                    type="number"
                    placeholder="ph no"
                    name="customer_ph_no"
                    value={CustomerData.customer_ph_no}
                    onChange={handleCustomerData}
                  />
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="secondary" onClick={toggle}>
              Close
            </Button>

            {editMode ? (
              loading ? (
                <button type="button" className="btn btn-dark " disabled>
                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                  Loading
                </button>
              ) : (
                <Button color="primary" onClick={handleButtonClick}>
                  Edit User
                </Button>
              )
            ) : loading ? (
              <button type="button" className="btn btn-dark " disabled>
                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                Loading
              </button>
            ) : (
              <Button color="primary" onClick={handleButtonClick}>
                Submit
              </Button>
            )}
          </ModalFooter>
        </Form>
      </div>
    </Modal>
  )
}

export default CustomerCreationModal
