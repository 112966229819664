import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"

const { v4: uuidv4 } = require("uuid")

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  FormFeedback,
  InputGroup,
  InputGroupText,
  Row,
} from "reactstrap"
import Select from "react-select"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import apiURl from "url"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
//Product Cat
import {
  useFetchProductCat,
  useFetchProductUnit,
} from "hooks/useFetchControlls"
//Warehouse
import useFetchWarehouses from "hooks/useFetchWarehouse"

import toastr from "toastr"
import "toastr/build/toastr.min.css"
import Dropzone from "react-dropzone"
import { formatBytes } from "pages/Functions/functions"
// import { storage } from "config/firebase"

import { useFormik } from "formik"
import * as Yup from "yup"
import { CategoryModal, UnitModal } from "components/Modals/ControlsModals"
import WareHouseModal from "components/WarehouseModal/houseModal"

const AddProducts = () => {
  //meta title
  document.title = "Add Product"
  const navigator = useNavigate()

  //handle fields
  const [ProductData, setProductData] = useState({
    Product_Name: "",
    Product_Cat: "",
    Product_Des: "",
    Product_warehouse: "",
    Product_unit: "",
    //stock
    Product_quantity: 1,
    Product_alert_quantity: 1,
    //price
    Product_Price: "",
    Tax_type: "",
    Discount_type: "",
    Discount_value: "",
    //image
    Product_file: [],
  })
  const handleProductData = e => {
    const { name, value } = e.target
    setProductData(prevData => ({
      ...prevData,
      [name]: value,
    }))
  }
  const handleProductCategory = selectedOption => {
    setProductData(prevData => ({
      ...prevData,
      Product_Cat: selectedOption ? selectedOption.value : "",
    }))
    validation.setFieldValue(
      "Product_Cat",
      selectedOption ? selectedOption.value : ""
    )
    validation.setFieldTouched("Product_Cat", true)
  }
  const handleProductWarehouse = selectedOption => {
    setProductData(prevData => ({
      ...prevData,
      Product_warehouse: selectedOption ? selectedOption.value : "",
    }))
    validation.setFieldValue(
      "Product_warehouse",
      selectedOption ? selectedOption.value : ""
    )
    validation.setFieldTouched("Product_warehouse", true)
  }
  const handleProductUnit = selectedOption => {
    setProductData(prevData => ({
      ...prevData,
      Product_unit: selectedOption ? selectedOption.value : "",
    }))
    validation.setFieldValue(
      "Product_unit",
      selectedOption ? selectedOption.value : ""
    )
    validation.setFieldTouched("Product_unit", true)
  }

  //handle images
  const handleAcceptedFiles = files => {
    if (files.length > 0) {
      const file = files[0]
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })

      setProductData({
        ...ProductData,
        Product_file: [file],
      })
    }
  }

  //Validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: ProductData,
    validationSchema: Yup.object({
      Product_Name: Yup.string().required("Product Name is required"),
      Product_Cat: Yup.string().required("Set Category"),
      Product_Price: Yup.string().required("Set Price"),
      Product_Des: Yup.string().required("Define Description"),
      Product_warehouse: Yup.string().required("Set Warehouse"),
      Product_unit: Yup.string().required("Set unit"),
    }),
    onSubmit: values => {
      console.log(values)
    },
  })
  const handleButtonClick = async () => {
    try {
      const isValid = await validation.validateForm()
      if (Object.keys(isValid).length === 0) {
        handleProductAdd()
      } else {
        toastr.error("Fill all the required fields")
      }
    } catch (error) {
      console.error("Error during form validation:", error)
    }
  }

  //final submittion
  const [loading, setLoading] = useState(false)
  const handleProductAdd = async () => {
    setLoading(true)
    try {
      // Upload images to Firebase Storage
      let uploadedImageUrl = ""
      // if (ProductData.Product_file.length > 0) {
      //   const file = ProductData.Product_file[0] // Only take the first file
      //   const fileRef = storage.ref(`products/${uuidv4()}-${file.name}`)
      //   const snapshot = await fileRef.put(file)
      //   uploadedImageUrl = await snapshot.ref.getDownloadURL()
      // }

      // Prepare FormData with product data and uploaded image URLs
      const PrdData = new FormData()
      PrdData.append("Product_Name", ProductData.Product_Name)
      PrdData.append("Product_Cat", ProductData.Product_Cat)
      PrdData.append("Product_warehouse", ProductData.Product_warehouse)
      PrdData.append("Product_unit", ProductData.Product_unit)
      PrdData.append("Product_Price", parseFloat(ProductData.Product_Price))
      PrdData.append(
        "Product_quantity",
        parseFloat(ProductData.Product_quantity)
      )
      PrdData.append(
        "Product_alert_quantity",
        parseFloat(ProductData.Product_alert_quantity)
      )
      PrdData.append("Tax_type", ProductData.Tax_type)
      PrdData.append("Discount_type", ProductData.Discount_type)
      PrdData.append("Discount_value", parseFloat(ProductData.Discount_value))
      PrdData.append("Product_Des", ProductData.Product_Des)
      PrdData.append("Product_Images", uploadedImageUrl) // Append the single image URL

      // Send POST request to API endpoint
      const response = await axios.post(
        `${apiURl}/api/addProductData`,
        PrdData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )

      // Handle response
      console.log("Response received:", response.data)

      if (response.data.success === "success") {
        toastr.success(response.data.message)
        navigator("/all-stock-products")
        setLoading(false)
        setProductData(prevData => ({
          ...prevData,
          Product_Name: "",
          Product_Cat: "",
          Product_Price: "",
          Product_Des: "",
          Product_warehouse: "",
          Product_unit: "",
          Product_alert_quantity: "",
          Product_quantity: "",
          Discount_type: "",
          Tax_type: "",
          Discount_value: "",
          Product_file: [],
        }))
      } else {
        toastr.error("Failed to add product.")
        setLoading(false)
      }
    } catch (error) {
      console.error("Error:", error)
      toastr.error("Failed to add product.")
      setLoading(false)
    }
  }

  //delete
  const handleDelete = name => {
    setProductData(prevData => ({
      ...prevData,
      Product_file: prevData.Product_file.filter(file => file.name !== name),
    }))
  }

  //Fetch Product Category
  const { productCategory, fetchProductsCategory } = useFetchProductCat()
  //Warehouses
  const { FHouse, fetchHouse } = useFetchWarehouses()
  //Units
  const { productUnit, fetchProductsUnit } = useFetchProductUnit()

  //close
  const closetab = () => {
    navigator("/all-stock-products")
  }

  //Add Prd Category
  const [prdCatModal, setPrdCatModal] = useState(false)
  const toggleModal = () => {
    setPrdCatModal(!prdCatModal)
  }
  const addCategory = () => {
    setPrdCatModal(true)
  }

  //Add Warehouse
  const [houseModal, setHouseModal] = useState(false)
  const [modalData, setModalData] = useState(false)
  const togglehouseModal = () => {
    setHouseModal(!houseModal)
    setModalData(false)
  }
  const addWareHouse = () => {
    setHouseModal(true)
    setModalData(true)
  }

  //Add Unit
  const [unitModal, setUnitModal] = useState(false)
  const toggleUnitModal = () => {
    setUnitModal(!unitModal)
  }
  const addUnit = () => {
    setUnitModal(true)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Ecommerce" breadcrumbItem="Add Product" />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <CardTitle>Basic Information</CardTitle>
                  <p className="card-title-desc mb-4">
                    Fill all information below
                  </p>

                  <Row>
                    <Col sm="6">
                      <div className="mb-3">
                        <Label htmlFor="productname">Product Name</Label>
                        <Input
                          id="productname"
                          name="Product_Name"
                          type="text"
                          className="form-control"
                          placeholder="Product Name"
                          value={ProductData.Product_Name}
                          onChange={handleProductData}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.Product_Name &&
                            validation.errors.Product_Name
                              ? true
                              : false
                          }
                        />

                        {validation.touched.Product_Name &&
                          validation.errors.Product_Name && (
                            <FormFeedback type="invalid">
                              {validation.errors.Product_Name}
                            </FormFeedback>
                          )}
                      </div>

                      <div className="mb-3">
                        <Label htmlFor="productdesc">Product Description</Label>
                        <textarea
                          className="form-control mb-3"
                          id="productdesc"
                          name="Product_Des"
                          rows="5"
                          placeholder="Product Description"
                          value={ProductData.Product_Des}
                          onChange={handleProductData}
                          onBlur={validation.handleBlur}
                        />
                        {validation.touched.Product_Des &&
                          validation.errors.Product_Des && (
                            <FormFeedback type="invalid">
                              {validation.errors.Product_Des}
                            </FormFeedback>
                          )}
                      </div>
                    </Col>

                    <Col sm="6">
                      <div className="mb-3">
                        <Label className="control-label">Category*</Label>{" "}
                        <span
                          style={{ color: "blue", cursor: "pointer" }}
                          onClick={addCategory}
                        >
                          {" "}
                          (Add More+){" "}
                        </span>
                        <Select
                          defaultValue="0"
                          className={`select2-selection ${
                            validation.touched.Product_Cat &&
                            validation.errors.Product_Cat
                              ? "is-invalid"
                              : ""
                          }`}
                          name="Product_Cat"
                          value={productCategory?.find(
                            category =>
                              category.value == ProductData.Product_Cat
                          )}
                          onBlur={() =>
                            validation.setFieldTouched("Product_Cat", true)
                          }
                          onChange={handleProductCategory}
                          options={[
                            ...(productCategory
                              ? productCategory
                              : [{ value: "", label: "No data Found" }]),
                          ]}
                        />
                        {validation.touched.Product_Cat &&
                          validation.errors.Product_Cat && (
                            <FormFeedback type="invalid">
                              {validation.errors.Product_Cat}
                            </FormFeedback>
                          )}
                      </div>
                      <div className="mb-3">
                        <Label
                          className="control-label"
                          htmlFor="Product_warehouse"
                        >
                          WareHouse*
                        </Label>
                        <span
                          style={{ color: "blue", cursor: "pointer" }}
                          onClick={addWareHouse}
                        >
                          {" "}
                          (Add More+){" "}
                        </span>
                        <Select
                          defaultValue="0"
                          className={`select2-selection ${
                            validation.touched.Product_warehouse &&
                            validation.errors.Product_warehouse
                              ? "is-invalid"
                              : ""
                          }`}
                          onChange={handleProductWarehouse}
                          onBlur={() =>
                            validation.setFieldTouched(
                              "Product_warehouse",
                              true
                            )
                          }
                          value={FHouse?.find(
                            option =>
                              option.value === ProductData.Product_warehouse
                          )}
                          options={[
                            ...(FHouse.length > 0
                              ? FHouse
                              : [{ value: "", label: "No data Found" }]),
                          ]}
                        />
                        {validation.touched.Product_warehouse &&
                          validation.errors.Product_warehouse && (
                            <FormFeedback type="invalid">
                              {validation.errors.Product_warehouse}
                            </FormFeedback>
                          )}
                      </div>
                      <div className="mb-3">
                        <Label className="control-label" htmlFor="Product_unit">
                          Unit*
                        </Label>
                        <span
                          style={{ color: "blue", cursor: "pointer" }}
                          onClick={addUnit}
                        >
                          {" "}
                          (Add More+){" "}
                        </span>
                        <Select
                          defaultValue="0"
                          className={`select2-selection ${
                            validation.touched.Product_unit &&
                            validation.errors.Product_unit
                              ? "is-invalid"
                              : ""
                          }`}
                          onChange={handleProductUnit}
                          onBlur={() =>
                            validation.setFieldTouched("Product_unit", true)
                          }
                          value={productUnit?.find(
                            option => option.value === ProductData.Product_unit
                          )}
                          options={[
                            ...(productUnit.length > 0
                              ? productUnit
                              : [{ value: "", label: "No data Found" }]),
                          ]}
                        />
                        {validation.touched.Product_unit &&
                          validation.errors.Product_unit && (
                            <FormFeedback type="invalid">
                              {validation.errors.Product_unit}
                            </FormFeedback>
                          )}
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <CardTitle>Stock Information</CardTitle>
                  <Row>
                    <Col sm="4">
                      <div className="mb-3">
                        <Label htmlFor="quantity_">Quantity</Label>
                        <Input
                          id="quantity_"
                          name="Product_quantity"
                          type="number"
                          placeholder="0"
                          min={0}
                          className="form-control"
                          value={ProductData.Product_quantity}
                          onChange={handleProductData}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.Product_quantity &&
                            validation.errors.Product_quantity
                              ? true
                              : false
                          }
                          onWheel={e => e.target.blur()}
                        />

                        {validation.touched.Product_quantity &&
                          validation.errors.Product_quantity && (
                            <FormFeedback type="invalid">
                              {validation.errors.Product_quantity}
                            </FormFeedback>
                          )}
                      </div>
                    </Col>
                    <Col sm="4">
                      <div className="mb-3">
                        <Label htmlFor="price">Price</Label>
                        <InputGroup>
                          <Input
                            id="price"
                            min={1}
                            name="Product_Price"
                            type="number"
                            className="form-control"
                            placeholder="Set Price"
                            value={ProductData.Product_Price}
                            onChange={handleProductData}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.Product_Price &&
                              validation.errors.Product_Price
                                ? true
                                : false
                            }
                            onWheel={e => e.target.blur()}
                          />

                          <Label className="input-group-text">$</Label>
                          {validation.touched.Product_Price &&
                            validation.errors.Product_Price && (
                              <FormFeedback type="invalid">
                                {validation.errors.Product_Price}
                              </FormFeedback>
                            )}
                        </InputGroup>
                      </div>
                    </Col>
                    <Col sm="4">
                      <div className="mb-3">
                        <Label className="control-label" htmlFor="Tax_type">
                          Tax Type
                        </Label>
                        <select
                          defaultValue="0"
                          className="form-select"
                          onChange={handleProductData}
                          name="Tax_type"
                          value={ProductData.Tax_type}
                        >
                          <option value="">Choose</option>
                          <option value="exclusive">Exclusive</option>
                          <option value="sale_tex">Sale tax</option>
                        </select>
                      </div>
                    </Col>
                    <Col sm="4">
                      <div className="mb-3">
                        <Label
                          className="control-label"
                          htmlFor="Discount_type"
                        >
                          Discount Type
                        </Label>
                        <select
                          defaultValue="0"
                          className="form-select"
                          onChange={handleProductData}
                          name="Discount_type"
                          value={ProductData.Discount_type}
                        >
                          <option value="">Choose</option>
                          <option value="percentage">Percentage</option>
                          <option value="cash">Cash</option>
                        </select>
                      </div>
                    </Col>
                    <Col sm="4">
                      <div className="mb-3">
                        <Label htmlFor="price">Discount Value</Label>
                        <Input
                          id="price"
                          min={1}
                          name="Discount_value"
                          type="number"
                          className="form-control"
                          placeholder="Value"
                          value={ProductData.Discount_value}
                          onChange={handleProductData}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.Discount_value &&
                            validation.errors.Discount_value
                              ? true
                              : false
                          }
                          onWheel={e => e.target.blur()}
                        />

                        {validation.touched.Discount_value &&
                          validation.errors.Discount_value && (
                            <FormFeedback type="invalid">
                              {validation.errors.Discount_value}
                            </FormFeedback>
                          )}
                      </div>
                    </Col>
                    <Col sm="4">
                      <div className="mb-3">
                        <Label htmlFor="alert_quantity_">Alert Quantity</Label>
                        <Input
                          id="alert_quantity_"
                          name="Product_alert_quantity"
                          type="number"
                          placeholder="0"
                          min={0}
                          className="form-control"
                          value={ProductData.Product_alert_quantity}
                          onChange={handleProductData}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.Product_alert_quantity &&
                            validation.errors.Product_alert_quantity
                              ? true
                              : false
                          }
                          onWheel={e => e.target.blur()}
                        />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <CardTitle className="mb-3">Product Images</CardTitle>
                  <Form>
                    <Row>
                      <Col sm={3} className="md-3">
                        <Dropzone
                          onDrop={acceptedFiles => {
                            handleAcceptedFiles(acceptedFiles)
                          }}
                          maxFiles={1}
                          accept="image/*"
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="dz-message needsclick">
                                  <div className="mb-3">
                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                  </div>
                                  <h4>Upload</h4>
                                </div>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                      </Col>
                      <Col sm={3} className="md-6">
                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          {ProductData.Product_file.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                      >
                                        {f.name}
                                      </Link>
                                      <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                      </p>
                                    </Col>

                                    <Col>
                                      <div className="float-end">
                                        <i
                                          className="mdi mdi-delete font-size-18"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => handleDelete(f.name)}
                                        ></i>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            )
                          })}
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
              <Row className="mb-3">
                <Col className="md-6">
                  <Button
                    onClick={closetab}
                    color="danger"
                    className="btn btn-danger "
                  >
                    Close
                  </Button>
                </Col>
                <Col className="md-6">
                  <div className="float-end d-flex flex-wrap gap-2">
                    {loading ? (
                      <button type="button" className="btn btn-dark " disabled>
                        <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                        Loading
                      </button>
                    ) : (
                      <Button
                        onClick={handleButtonClick}
                        color="primary"
                        className="btn btn-primary "
                      >
                        Add Product
                      </Button>
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <CategoryModal
        isOpen1={prdCatModal}
        toggle1={toggleModal}
        fetchPrdCat={fetchProductsCategory}
      />
      <UnitModal
        isOpen2={unitModal}
        toggle2={toggleUnitModal}
        fetchPrdUnit={fetchProductsUnit}
      />
      <WareHouseModal
        isOpen={houseModal}
        toggle={togglehouseModal}
        modalData={modalData}
        fetchHouse={fetchHouse}
      />
    </React.Fragment>
  )
}

export default AddProducts
